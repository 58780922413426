import { Override } from "../types/index";
import { WeeklyReportTemplateData as WeeklyReportTemplateDataDto } from "./client";
import { TransformDomain } from "./types";

export type WeeklyReportTemplateData = Override<WeeklyReportTemplateDataDto, {}>;

export function dtoToWeeklyReportTemplateData(dto: WeeklyReportTemplateDataDto): WeeklyReportTemplateData {
  return {
    ...dto,
  };
}

export function weeklyReportTemplateDataToDto(
  oneOnOne: Partial<WeeklyReportTemplateData>
): WeeklyReportTemplateDataDto {
  return {
    ...oneOnOne,
  };
}

export class WeeklyReportsDomain extends TransformDomain<WeeklyReportTemplateData, WeeklyReportTemplateDataDto> {
  resource = "WeeklyReport";
  cacheKey = "weeklyReport";
  pk = "id";

  public serialize = weeklyReportTemplateDataToDto;
  public deserialize = dtoToWeeklyReportTemplateData;

  social = this.manageErrors(this.deserializeResponse((id: string, verificationKey?: string) => this.api.weeklyReport.social({ id, verificationKey })));

  socialImage = this.manageErrors(this.deserializeResponse(this.api.weeklyReport.socialImage));

  unsubscribe = this.manageErrors(this.api.weeklyReport.unsubscribe);
}
