/* tslint:disable */
/* eslint-disable */

/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ## A custom template from the reclaim-api/api-client         ##
 * ## repo was used.                                            ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import axios, { AxiosResponse, AxiosRequestConfig, AxiosInstance } from "axios";

export type AbstractLinearTeam = object;

export interface AppNotificationsSettings {
  enabled?: boolean;
  unscheduledPriority?: boolean;
}

export interface AssistCompleted {
  startTime?: string;
  endTime?: string;
  notificationKeys?: string[];
  events?: Event[];
}

export interface AssistCompletedSubscription {
  subscriptionType?: SubscriptionType;
}

export interface AssistDetails {
  type?: AssistType;
  customHabit?: boolean;
  habitOrTask?: boolean;
  task?: boolean;
  conferenceBuffer?: boolean;
  status?: AssistStatus;
  travelNewEventId?: string;
  conferenceEventId?: string;
  lastControlledUpdate?: string;
  lastControlledHash?: number;
  defended?: boolean;
  pinned?: boolean;
  dailyHabitId?: number;
  taskId?: number;
  taskIndex?: number;
  policyOverride?: AssistPolicyOverride;
  lastManualAdjustment?: string;
  recurringAssignmentType?: RecurringAssignmentType;
  eventType?: ReclaimEventType;
}

export interface AssistPlanned {
  startTime?: string;
  endTime?: string;
  notificationKeys?: string[];
  events?: Event[];
  expandedPlan?: ExpandedPlan;
}

export interface AssistPlannedSubscription {
  subscriptionType?: SubscriptionType;
  startTime?: string;
  endTime?: string;
}

export interface AssistPolicyOverride {
  windowStart?: LocalTime;
  idealTime?: LocalTime;
  windowEnd?: LocalTime;

  /** Duration min in minutes. */
  durationMin?: number;

  /** Duration max in minutes. */
  durationMax?: number;
  forceDefend?: boolean;
}

export interface AssistSettings {
  travel?: boolean;
  otherTravelDuration?: number;
  conferenceBuffer?: boolean;
  conferenceBufferDuration?: number;
  conferenceBufferPrivate?: boolean;
  customConferenceBufferTitle?: string;
  focus?: boolean;
  bypassed?: boolean;
}

export enum AssistStatus {
  CONTROLLED = "CONTROLLED",
  RELEASED = "RELEASED",
  ARCHIVED = "ARCHIVED",
}

export enum AssistType {
  TASK = "TASK",
  CUSTOM_DAILY = "CUSTOM_DAILY",
  CATCHUP_AM = "CATCHUP_AM",
  CATCHUP_PM = "CATCHUP_PM",
  LUNCH = "LUNCH",
  FOCUS = "FOCUS",
  TRAVEL_PRE = "TRAVEL_PRE",
  TRAVEL_POST = "TRAVEL_POST",
  CONBUF = "CONBUF",
}

export interface Calendar {
  id?: number;
  name?: string;
  colorHex?: string;
  user?: User;
  userId?: string;
  calendarId?: string;
  credentialId?: number;
  syncToken?: string;
  watchGuid?: string;
  watchResourceId?: string;
  data?: object;
  accessDomainRead?: boolean;
  authorized?: boolean;
  created?: string;
  deleted?: string;
  type?: CalendarType;
  systemCalendar?: boolean;
  nextRefresh?: string;
  timezone?: ZoneId;
  primaryCalendarId?: string;
  priority?: number;
  projectId?: number;
  syncSettings: SyncSettings;
  lastSynced?: string;
}

export enum CalendarType {
  PRIMARY = "PRIMARY",
  SHADOW = "SHADOW",
  PERSONAL = "PERSONAL",
  PRIORITY = "PRIORITY",
  PROJECT = "PROJECT",
}

export enum CalendarAccess {
  NONE = "NONE",
  SETTINGS_ONLY = "SETTINGS_ONLY",
  READ = "READ",
  WRITE = "WRITE",
  SUPER = "SUPER",
}

export interface CalendarSettings {
  enabled?: boolean;
}

export type CalendarSyncPolicy = SyncSettings & {
  sourceCalendarId?: number;
  targetCalendarId?: number;
  sourceCalendar?: Calendar;
  targetCalendar?: Calendar;
  enabled?: boolean;
};

export interface ColorsSettings {
  enabled?: boolean;
  prioritiesEnabled?: boolean;
  projectsEnabled?: boolean;
  categoriesEnabled?: boolean;
  lastModified?: string;
  priorities?: Record<string, EventColor>;
  categories?: Record<string, EventColor>;
}

export enum ConferenceType {
  GOOGLE_MEET = "GOOGLE_MEET",
  ZOOM = "ZOOM",
  CUSTOM = "CUSTOM",
}

export interface ConnectedAccount {
  id?: number;
  name?: string;
  valid?: boolean;
  avatar?: string;
  main?: boolean;
  canDelete?: boolean;
  type?: ConnectedAccountType;
  identityAccess?: boolean;
  calendarAccess?: CalendarAccess;
  taskAccess?: boolean;
  unique?: boolean;
  lastSynced?: string;
  switchToMainURI?: string;
  repairURI?: string;
}

export enum ConnectedAccountType {
  GOOGLE = "GOOGLE",
}

export interface Credential {
  id?: number;
  userId?: string;
  provider?: string;
  principal?: string;
  name?: string;
  scopes?: string;
  data?: Object;
  avatar?: string;
  userInfo?: GoogleUserInfo;
  updated?: string;
  valid?: boolean;
}

export interface DailyHabit {
  type?: AssistType;
  id?: number;
  index?: number;
  created?: string;
  updated?: string;
  enabled?: boolean;
  title?: string | null;
  defendedDescription?: string;
  privateDescription?: string;
  additionalDescription?: string;
  recurringAssignmentType?: RecurringAssignmentType;
  notification?: boolean;
  windowStart?: LocalTime;
  windowEnd?: LocalTime;
  idealTime?: LocalTime;
  idealDay?: DayOfWeek;
  recurrence?: Recurrence;
  rrule?: string;
  durationMin?: number;
  durationMax?: number;
  eventCategory?: EventCategory;
  daysActive?: DayOfWeek[];
  eventColor?: EventColor;

  /** Set for websocket callbacks. */
  deleted?: boolean;
  invitees?: ThinPerson[];
  alwaysPrivate?: boolean;
  autoDecline?: boolean;
  autoDeclineText?: string;

  /** Returns -1, 0, or 1 based on a sliding scale of least aggressive to most aggressive in terms of how and when we decided to defend this habit. */
  defenseAggression?: number;
  elevated?: boolean;
  priority?: Smurf;
  priorityUntil?: string;
  snoozeUntil?: string | null;
  timesPerPeriod?: number;
  timePolicy?: string | null;
  conferenceType?: ConferenceType;
  conferenceData?: object | null;
  location?: string | null;
  status?: RecurringAssignmentStatus;
  reservedWords?: string[];
}

export interface DailyHabitSubscription {
  subscriptionType?: SubscriptionType;
}

export interface DayHours {
  intervals?: LocalTimeInterval[];
  endOfDay?: LocalTime;
}

export enum DayOfWeek {
  MONDAY = "MONDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
  THURSDAY = "THURSDAY",
  FRIDAY = "FRIDAY",
  SATURDAY = "SATURDAY",
  SUNDAY = "SUNDAY",
}

export type DefaultSyncSettings = SyncSettings;

export interface Duration {
  units?: TemporalUnit[];
  zero?: boolean;
  negative?: boolean;
  seconds?: number;
  nano?: number;
}

export interface Event {
  key?: EventKey;
  underAssistControl?: boolean;
  assist?: AssistDetails;
  reclaimEventType?: ReclaimEventType;
  personalSync?: boolean;
  reclaimManaged?: boolean;
  id?: string;
  eventId?: string;
  actions?: ReclaimEventActions;
  priority?: Priority;
  smurf?: Smurf;
  title?: string;
  titleSeenByOthers?: string;
  location?: string;
  onlineMeetingUrl?: string;
  description?: string;
  sourceDetails?: EventSourceDetails;
  status?: EventStatus;
  published?: boolean;
  type?: EventType;
  subType?: EventSubType;
  meetingType?: MeetingType;
  categoryOverride?: EventCategory;
  eventStart?: string;
  eventEnd?: string;
  timeChunks?: number;
  allocatedTimeChunks?: number;
  recurringEventId?: string;
  updated?: string;
  recurringException?: boolean;
  recurrence?: string;
  recurrenceMax?: string;
  recurring?: boolean;
  recurringInstance?: boolean;
  private?: boolean;
  public?: boolean;
  color?: EventColor;
  organizer?: string;
  numAttendees?: number;
  free?: boolean;
  scoredType?: ScoredType;
  category?: EventCategory;
  rsvpStatus: EventResponseStatus;
  requiresTravel?: boolean;
  conferenceCall?: boolean;
  projectIds?: number[];
  projects?: Project[];
  etag?: string;
  version?: string;
  forceBusy?: Void;
}

export enum EventStatus {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
  CANCELLED = "CANCELLED",
}

export type EventCategory = object;

export enum EventColor {
  NONE = "NONE",
  LAVENDER = "LAVENDER",
  SAGE = "SAGE",
  GRAPE = "GRAPE",
  FLAMINGO = "FLAMINGO",
  BANANA = "BANANA",
  TANGERINE = "TANGERINE",
  PEACOCK = "PEACOCK",
  GRAPHITE = "GRAPHITE",
  BLUEBERRY = "BLUEBERRY",
  BASIL = "BASIL",
  TOMATO = "TOMATO",
}

export interface EventKey {
  eventId?: string;
  calendarId?: number;
}

export enum EventResponseStatus {
  None = "None",
  Organizer = "Organizer",
  Accepted = "Accepted",
  Declined = "Declined",
  TentativelyAccepted = "TentativelyAccepted",
  NotResponded = "NotResponded",
}

export interface EventSourceDetails {
  writable?: boolean;
  calendarId?: number;
  eventId?: string;
  etag?: string;
  eventKey?: EventKey;
  base64Id?: string;
  url?: string;
  title?: string;
  description?: string;
}

export enum EventSubType {
  ONE_ON_ONE = "ONE_ON_ONE",
  STAFF_MEETING = "STAFF_MEETING",
  OP_REVIEW = "OP_REVIEW",
  EXTERNAL = "EXTERNAL",
  IDEATION = "IDEATION",
  FOCUS = "FOCUS",
  PRODUCTIVITY = "PRODUCTIVITY",
  TRAVEL = "TRAVEL",
  FLIGHT = "FLIGHT",
  RECLAIM = "RECLAIM",
  VACATION = "VACATION",
  HEALTH = "HEALTH",
  ERRAND = "ERRAND",
  OTHER_PERSONAL = "OTHER_PERSONAL",
  UNKNOWN = "UNKNOWN",
}

export enum EventType {
  MEETING = "MEETING",
  WORK = "WORK",
  LOGISTICS = "LOGISTICS",
  PERSONAL = "PERSONAL",
}

export interface EventsSubscription {
  subscriptionType?: SubscriptionType;
}

export interface ExpandedPlan {
  oneOnOnes?: OneOnOnePlanItem[];
  scheduled?: PlanItem[];
  atRisk?: PlanItem[];
  recentTasks?: PlanItem[];
  done?: PlanItem[];
}

export interface GoogleUserInfo {
  sub?: string;
  email?: string;
  email_verified?: boolean;
  name?: string;
  given_name?: string;
  family_name?: string;
  picture?: string;
  locale?: string;
  hd?: string;
}

export type GroupedSummaryInsight = Insight & { summaries?: Record<string, SummaryInsight>; summary?: SummaryInsight };

export type GroupedValueInsight = Insight & {
  values?: Record<string, ValueInsight>;
  initialValue?: Object;
  summary?: SummaryInsight;
};

export interface HabitPlanItem {
  maxPossible?: number;
  completed?: number;
  scheduled?: number;
}

export interface Insight {
  unit?: InsightUnit;
  recentEvents?: string[];
}

export enum InsightUnit {
  TIME_CHUNKS = "TIME_CHUNKS",
  PERCENT = "PERCENT",
  COUNT = "COUNT",
}

export interface Insights {
  start?: string;
  end?: string;
  numDays?: number;
  numEvents?: number;
  eventCache?: Record<string, Event>;
  values?: Record<string, ValueInsight>;
  groupedValues?: Record<string, GroupedValueInsight>;
  summaries?: Record<string, SummaryInsight>;
  groupedSummaries?: Record<string, GroupedSummaryInsight>;
}

export interface LinearIntegration {
  id?: string;
  requiresLabel?: boolean;
  availableTeams?: AbstractLinearTeam[];
  selectedTeamIds?: string[];
}

export interface LinearIntegrationPatch {
  requiresLabel?: boolean;
  selectedTeamIds?: string[];
}

export interface LinearTaskDetails {
  taskId?: number;
  owner?: boolean;
  status?: TaskStatus;
}

export type LocalTime = string;

export interface LocalTimeInterval {
  start?: LocalTime;
  end?: LocalTime;
  duration?: Duration;
}

export interface Locale {
  language?: string;
  script?: string;
  country?: string;
  variant?: string;
  extensionKeys?: string[];
  unicodeLocaleAttributes?: string[];
  unicodeLocaleKeys?: string[];
  ISO3Language?: string;
  ISO3Country?: string;
  displayLanguage?: string;
  displayScript?: string;
  displayCountry?: string;
  displayVariant?: string;
  displayName?: string;
}

export enum MeetingType {
  DIRECT_11 = "DIRECT_11",
  PEER_11 = "PEER_11",
  SKIP_11 = "SKIP_11",
  XFUNC_11 = "XFUNC_11",
  OTHER_11 = "OTHER_11",
  TEAM = "TEAM",
  XFUNC_TEAM = "XFUNC_TEAM",
  EXEC = "EXEC",
  SKIP_TEAM = "SKIP_TEAM",
  ALL_HANDS = "ALL_HANDS",
  ONBOARDING_TRAINING = "ONBOARDING_TRAINING",
  COMPANY_EVENT = "COMPANY_EVENT",
  BOARD_MEETING = "BOARD_MEETING",
  OTHER_STAFF = "OTHER_STAFF",
  DEAL_REVIEW = "DEAL_REVIEW",
  EXECUTION_REVIEW = "EXECUTION_REVIEW",
  DEMO = "DEMO",
  PLANNING_MEETING = "PLANNING_MEETING",
  RETRO = "RETRO",
  EMERGENCY = "EMERGENCY",
  PEOPLE_REVIEW = "PEOPLE_REVIEW",
  OTHER_OP = "OTHER_OP",
  ROADMAP = "ROADMAP",
  CUST_MEETING = "CUST_MEETING",
  CUST_RESEARCH = "CUST_RESEARCH",
  CUST_URGENT = "CUST_URGENT",
  RECRUIT_SCREEN = "RECRUIT_SCREEN",
  OTHER_EXTERNAL = "OTHER_EXTERNAL",
  DESIGN_BRAIN = "DESIGN_BRAIN",
  TECH_REVIEW = "TECH_REVIEW",
  KICKOFF = "KICKOFF",
  REQ_BRAIN = "REQ_BRAIN",
  OTHER_IDEATION = "OTHER_IDEATION",
  UNKNOWN = "UNKNOWN",
}

export enum Month {
  JANUARY = "JANUARY",
  FEBRUARY = "FEBRUARY",
  MARCH = "MARCH",
  APRIL = "APRIL",
  MAY = "MAY",
  JUNE = "JUNE",
  JULY = "JULY",
  AUGUST = "AUGUST",
  SEPTEMBER = "SEPTEMBER",
  OCTOBER = "OCTOBER",
  NOVEMBER = "NOVEMBER",
  DECEMBER = "DECEMBER",
}

export type Object = object;

export type OneOnOnePlanItem = PlanItem & { doneDate?: string };

export interface OneOnOneSubscription {
  subscriptionType?: SubscriptionType;
}

export interface PlanItem {
  title?: string;
  type?: PlanItemType;
  status?: PlanItemStatus;
  data?: Prioritizable;
  priority?: Smurf;
  actions?: PrioritizableActions;
}

export enum PlanItemStatus {
  SCHEDULED = "SCHEDULED",
  UNSCHEDULED = "UNSCHEDULED",
  COMPLETED = "COMPLETED",
}

export enum PlanItemType {
  TASK = "TASK",
  HABIT = "HABIT",
  ONE_ON_ONE = "ONE_ON_ONE",
}

export interface PrioritiesSettings {
  enabled?: boolean;
}

export interface Prioritizable {
  priority?: Smurf;
  priorityUntil?: string;
}

export interface PrioritizableActions {
  prioritizable?: boolean;
  pushable?: boolean;
  pullable?: boolean;
  projectAssociable?: boolean;
  extendable?: boolean;
  editable?: boolean;
  deletable?: boolean;
  finishable?: boolean;
  unfinishable?: boolean;
}

export interface Priority {
  id?: number;
  user?: User;
  name?: string;
  targetTimeChunks?: number;
  index?: number;
  created?: string;
  activated?: string;
  active?: boolean;
}

export interface Project {
  id?: number;
  name?: string;
  priority?: Smurf;
  priorityUntil?: string;
  prioritized?: boolean;
  color?: EventColor;
  created?: string;
  updated?: string;
  taskIds?: number[];
  eventKeys?: EventKey[] | null | null;
  calendar?: Calendar;
}

export enum ProjectInclude {
  ID = "ID",
  FULL = "FULL",
}

export interface ProjectsSettings {
  enabled?: boolean;
}

export interface ReclaimEventActions {
  editable?: boolean;
  lockable?: boolean;
  deletable?: boolean;
  projectAssociable?: boolean;
  categoryOverridable?: boolean;
  startable?: boolean;
  stoppable?: boolean;
  extendable?: boolean;
  restartable?: boolean;
  snoozeable?: boolean;
  finishable?: boolean;
  unfinishable?: boolean;
  rescheduleable?: boolean;
}

export enum ReclaimEventType {
  USER = "USER",
  SYNC = "SYNC",
  HABIT_ASSIGNMENT = "HABIT_ASSIGNMENT",
  ONE_ON_ONE_ASSIGNMENT = "ONE_ON_ONE_ASSIGNMENT",
  TASK_ASSIGNMENT = "TASK_ASSIGNMENT",
  CONF_BUFFER = "CONF_BUFFER",
  TRAVEL_BUFFER = "TRAVEL_BUFFER",
  UNKNOWN = "UNKNOWN",
}

export enum ReclaimSku {
  ASSISTANT = "ASSISTANT",
  PRO = "PRO",
  TEAM = "TEAM",
}

export enum Recurrence {
  Weekly = "Weekly",
  Biweekly = "Biweekly",
  Monthly = "Monthly",
  Quarterly = "Quarterly",
}

export interface RecurringAssignmentAttendee {
  id?: number;
  dailyHabitId?: number;
  attendeeUserId?: string;
  email?: string;
  status?: RecurringAssignmentAttendeeStatus;
  timePolicy?: TimePolicy;
  inviteKey?: string;
  originalEventId?: string;
  personalizedInviteMessage?: string;
  reminderSent?: boolean;
  inviteSentAt?: string;
  inviteEmailId?: number;
  reminderEmailId?: number;
}

export enum RecurringAssignmentAttendeeStatus {
  INVITING = "INVITING",
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
}

export interface RecurringAssignmentInstance {
  instanceId?: number;
  eventId?: string;
  eventKey?: EventKey;
  eventStatus?: EventStatus;
  etag?: string;
  attendees?: object[];
  start?: string;
  end?: string;
}

export enum RecurringAssignmentStatus {
  NEW = "NEW",
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
  SCHEDULED = "SCHEDULED",
}

export enum RecurringAssignmentType {
  ONE_ON_ONE = "ONE_ON_ONE",
  DAILY_HABIT = "DAILY_HABIT",
  TASK = "TASK",
}

export interface RecurringOneOnOne {
  id?: number;
  created?: string;
  updated?: string;
  enabled?: boolean;
  notification?: boolean;
  windowStart?: LocalTime;
  windowEnd?: LocalTime;
  idealTime?: LocalTime;
  idealDay?: DayOfWeek;
  recurrence?: Recurrence;
  duration?: number;
  daysActive?: DayOfWeek[];

  /** Set for websocket callbacks. */
  deleted?: boolean;
  invitee: ThinPerson;
  inviteKey?: string | null;
  priority?: Smurf;
  priorityUntil?: string;
  snoozeUntil?: string | null;
  timePolicy?: string | null;
  assistTimePolicy?: TimePolicy;
  effectiveTimePolicy?: TimePolicy;
  conferenceType?: ConferenceType;
  conferenceData?: object | null;
  location?: string | null;
  status?: RecurringAssignmentStatus;
  organizerUserId?: string;
  organizer?: ThinPerson;
  additionalDescription?: string;
  recurringEventId?: string;
  instances?: RecurringAssignmentInstance[];
  personalizedInviteMessage?: string | null;
  title?: string;
  displayTitle?: string;
  organizersTimeZone?: string;
}

export interface ReferralStats {
  signedUp?: number;
  active?: number;
  deleted?: number;
}

export type ScoredType = object;

export interface Settings {
  autoAddHangouts?: boolean;
  defaultEventLength?: number;
  weekStart?: number;
  format24HourTime?: boolean;
  locale?: string;
  showDeclinedEvents?: boolean;
  timezone?: string;
}

export enum SkipReason {
  managed_by_reclaim = "managed_by_reclaim",
  cancelled_recurring = "cancelled_recurring",
  skip_existing = "skip_existing",
  already_cancelled = "already_cancelled",
  all_day = "all_day",
  nosync = "nosync",
  work_participating = "work_participating",
  declined = "declined",
  private_free = "private_free",
  working_hours = "working_hours",
}

export interface SlackSettings {
  enabled?: boolean;
  personalSyncNotifyNew?: boolean;
  personalSyncNotifyUpdated?: boolean;
  personalSyncNotifyDeleted?: boolean;
  personalSyncNotificationsIncludingSelf?: boolean;
  habitNotifyUpcoming?: boolean;
  taskNotifyUpcoming?: boolean;
  travelNotify?: boolean;
  statusSync?: SlackSettingsStatusSync;
  statusEnabled?: boolean;
  privateStatus?: SlackStatusSetting;
  statuses?: Record<string, SlackStatusSetting>;
}

export enum SlackSettingsStatusSync {
  OFF = "OFF",
  DEFAULT = "DEFAULT",
  BUSY = "BUSY",
  CUSTOM = "CUSTOM",
}

export interface SlackStatusSetting {
  fallbackEmoji?: string;
  emoji?: string;
  template?: string;

  /** Returns true if do not disturb. */
  dnd?: boolean;
  enabled?: boolean;
  event?: Event;
  text?: string;
}

export interface SmartOneOnOneSettings {
  enabled?: boolean;
}

export enum Smurf {
  PRIORITIZE = "PRIORITIZE",
  DEFAULT = "DEFAULT",
}

export type SubscribeAction = WebSocketRequestAction & { subscription?: Subscription; subscriptionId?: string };

export interface Subscription {
  subscriptionType?: SubscriptionType;
}

export enum SubscriptionType {
  Events = "Events",
  SyncStatus = "SyncStatus",
  DailyHabit = "DailyHabit",
  OneOnOne = "OneOnOne",
  Task = "Task",
  AssistCompleted = "AssistCompleted",
  AssistPlanned = "AssistPlanned",
  NewSubscription = "NewSubscription",
}

export type SummaryInsight = Insight & { count?: number; sum?: number; min?: number; max?: number };

export enum SyncCalendarType {
  BUSINESS = "BUSINESS",
  PERSONAL = "PERSONAL",
  TRAVEL = "TRAVEL",
}

export interface SyncEvent {
  sourceEtag?: string;
  sourceEventId?: string;
  sourceCalendarId?: number;
  targetCalendarId?: number;
  targetEventId?: string;
  data?: object;
  responseData?: object;
  eventSourceDetails?: EventSourceDetails;
  eventStart?: string;
  synced?: boolean;
  recurrence?: string;
  exceptionClass?: string;
  exceptionMessage?: string;
  recurringEventId?: string;
  skipReason?: SkipReason;
  oldTargetEventId?: string;
}

export interface SyncFeatureSettings {
  enabled?: boolean;
}

export interface SyncSettings {
  transparency?: SyncTransparency;
  workingHours?: boolean;
  defaultType?: EventType;
  type?: SyncCalendarType;
  color?: EventColor;
  syncReclaimEvents?: boolean;
}

export interface SyncStatusSubscription {
  subscriptionType?: SubscriptionType;
}

export enum SyncTransparency {
  DEFAULT = "DEFAULT",
  SEMI_PRIVATE = "SEMI_PRIVATE",
  PRIVATE = "PRIVATE",
  PUBLIC = "PUBLIC",
  SEMI_PRIVATE_ALT = "SEMI_PRIVATE_ALT",
}

export interface Task {
  recurringAssignmentType?: RecurringAssignmentType;
  type?: AssistType;
  id?: number;
  status?: TaskStatus;
  title?: string;
  notes?: string;
  timeChunksRequired?: number;
  timeChunksSpent?: number;
  timeChunksRemaining?: number;
  minChunkSize?: number | null;
  maxChunkSize?: number | null;
  due?: string;
  created?: string;
  updated?: string;
  settingsLastModified?: string;
  finished?: string | null;
  snoozeUntil?: string | null;
  priority?: Smurf;
  priorityUntil?: string;
  eventCategory?: EventCategory;
  eventColor?: EventColor;

  /** This is only used by websockets to indicate when a task has been deleted. */
  deleted?: boolean;
  invitees?: ThinPerson[];
  alwaysPrivate?: boolean;
  autoDecline?: boolean;
  autoDeclineText?: string;
  source?: TaskSource;
  googleTaskListId?: string;
  googleTaskId?: string;
  googleTask?: object;
  projectIds?: number[];
  projects?: Project[];
  instances?: TaskInstance[];
}

export interface TaskDefaults {
  timeChunksRequired?: number;
  commsTimeChunksRequired?: number;
  delayedStartInMinutes?: number;
  dueInDays?: number;
  category?: EventCategory;
  alwaysPrivate?: boolean;
  minChunkSize?: number;
  maxChunkSize?: number;
}

export interface TaskInstance {
  taskId?: number;
  eventStatus?: EventStatus;
  eventId?: string;
  eventKey?: EventKey;
  index?: number;
  status?: TaskInstanceStatus;
  etag?: string;
  start?: string;
  end?: string;
  eventStart?: string;
  eventEnd?: string;
  timeChunks?: number;
  upcoming?: boolean;
}

export enum TaskInstanceStatus {
  DONE = "DONE",
  ACTIVE = "ACTIVE",
  PENDING = "PENDING",
  ABORTED = "ABORTED",
}

export interface TaskPlanItem {
  timeChunksRequired?: number;
  timeChunksSpent?: number;
  timeChunksRemaining?: number;
  percentComplete?: number;
  overdue?: boolean;
  atRisk?: boolean;
}

export interface TaskSettings {
  enabled?: boolean;
  googleTasks?: boolean;
  defaults: TaskDefaults;
}

export interface TaskSource {
  source?: TaskSourceSOURCE;
  id?: string;
}

export enum TaskSourceSOURCE {
  TODOIST = "TODOIST",
  TRELLO = "TRELLO",
  GTASKS = "GTASKS",
}

export enum TaskStatus {
  NEW = "NEW",
  SCHEDULED = "SCHEDULED",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETE = "COMPLETE",
  CANCELLED = "CANCELLED",
  ARCHIVED = "ARCHIVED",
}

export interface TaskSubscription {
  subscriptionType?: SubscriptionType;
}

export interface TemporalUnit {
  duration?: Duration;
  durationEstimated?: boolean;
  dateBased?: boolean;
  timeBased?: boolean;
}

export interface ThinPerson {
  /** Returns the Reclaim user id if we have it. */
  userId?: string | null;
  email: string;
  name?: string;
  avatarUrl?: string;
  firstName?: string;
  lastName?: string;
}

export interface TimePolicy {
  startOfWeek?: DayOfWeek;
  endOfWeek?: DayOfWeek;
  dayHours?: Record<string, DayHours>;
}

export interface TimeSpentDuringWeek {
  eventType?: EventType;
  eventTypeFriendly?: string;
  hours?: number;
  percentChange?: number;
  noChange?: boolean;
  increased?: boolean;
  infinity?: boolean;
}

export interface UnsubscribeAction {
  subscriptionId?: string;
}

export interface User {
  id?: string;
  email?: string;
  principal?: string;
  provider?: string;
  name?: string;
  firstName?: string;
  lastName?: string;
  avatarUrl?: string;
  admin?: boolean;
  syncUser?: boolean;
  slackEnabled?: boolean;
  impersonated?: boolean;
  timestampOffsetMs?: number;
  features?: UserSettings;
  settings?: Settings;
  created?: string;
  deleted?: string;
  onboarded?: boolean;
  trackingCode?: string;
  locale?: Locale;
  likelyPersonal?: boolean;
  apiKey?: string;
  sku: ReclaimSku;
  refCode?: string;
  primaryCalendarId?: string;
  timezone?: ZoneId;
  lastSynced?: string;
}

export interface UserInterests {
  tasks?: boolean;
  priorities?: boolean;
  office365?: boolean;
  calendar?: boolean;
  asana?: boolean;
  trello?: boolean;
  todoist?: boolean;
  jira?: boolean;
  linear?: boolean;
}

export interface UserOnboard {
  habits?: boolean;
  tasks?: boolean;
  googleTasks?: boolean;
  planItemPrioritized?: boolean;
  smartOneOnOnes?: boolean;
  bufferTime?: boolean;
}

export interface UserSettings {
  assistSettings: AssistSettings;
  scheduler?: number;
  extraScopes?: boolean;
  workingHours: WorkingHours;
  timePolicies: Record<string, TimePolicy>;
  taskSettings: TaskSettings;
  priorities: PrioritiesSettings;
  colors: ColorsSettings;
  calendar: CalendarSettings;
  projects: ProjectsSettings;
  sync: SyncFeatureSettings;
  defaultSyncSettings: DefaultSyncSettings;
  slackSettings: SlackSettings;
  appNotifications: AppNotificationsSettings;
  interests: UserInterests;
  onboard: UserOnboard;
  weeklyReport: WeeklyReport;
  smartOneOnOnes: SmartOneOnOneSettings;
}

export interface UserTemplateData {
  firstName?: string;
  trackingCode?: string;
  slackEnabled?: boolean;
  slackStatusEnabled?: boolean;
}

export enum UserTrait {
  EMAIL = "EMAIL",
  AUTH_ID = "AUTH_ID",
  FULL_NAME = "FULL_NAME",
  FIRST_NAME = "FIRST_NAME",
  LAST_NAME = "LAST_NAME",
  CREATED_AT = "CREATED_AT",
  SIGNED_UP = "SIGNED_UP",
  MAIN_TIMEZONE = "MAIN_TIMEZONE",
  REFERRAL_CODE = "REFERRAL_CODE",
  SYNC_USER = "SYNC_USER",
  ONBOARDED = "ONBOARDED",
  LIKELY_PERSONAL = "LIKELY_PERSONAL",
  CLUSTERDEX = "CLUSTERDEX",
  PRIMARY_CALENDAR_LINKED = "PRIMARY_CALENDAR_LINKED",
  PRIMARY_CALENDAR_ID = "PRIMARY_CALENDAR_ID",
  PERSONAL_CALENDARS_COUNT = "PERSONAL_CALENDARS_COUNT",
  PERSONAL_CALENDAR_LINKED = "PERSONAL_CALENDAR_LINKED",
  PERSONAL_CALENDAR_ID = "PERSONAL_CALENDAR_ID",
  ONE_ON_ONES_ACTIVE = "ONE_ON_ONES_ACTIVE",
  ONE_ON_ONES_PENDING = "ONE_ON_ONES_PENDING",
  ONE_ON_ONES_ORGANIZED = "ONE_ON_ONES_ORGANIZED",
  ONE_ON_ONES_INVITED_TO = "ONE_ON_ONES_INVITED_TO",
  ONE_ON_ONES_TOTAL = "ONE_ON_ONES_TOTAL",
  ONE_ON_ONES_DETECTED = "ONE_ON_ONES_DETECTED",
  DAILY_HABITS_ACTIVE = "DAILY_HABITS_ACTIVE",
  DAILY_HABITS_TOTAL = "DAILY_HABITS_TOTAL",
  DAILY_HABITS_ACTIVATED = "DAILY_HABITS_ACTIVATED",
  ONBOARDING_ENABLED_HABITS = "ONBOARDING_ENABLED_HABITS",
  TASKS_TOTAL = "TASKS_TOTAL",
  TASKS_RECENT = "TASKS_RECENT",
  PRIORITIES_ESTABLISHED = "PRIORITIES_ESTABLISHED",
  PRIORITIES_LINKED = "PRIORITIES_LINKED",
  TRAVEL_TIME_BLOCKED = "TRAVEL_TIME_BLOCKED",
  CONFERENCE_BUFFER_BLOCKED = "CONFERENCE_BUFFER_BLOCKED",
  SLACK_ACTION_TAKEN = "SLACK_ACTION_TAKEN",
  FEATURE_MODIFY_CALENDAR = "FEATURE_MODIFY_CALENDAR",
  DOMAIN = "DOMAIN",
  DOMAIN_SLACK_USERS = "DOMAIN_SLACK_USERS",
  FEATURE_ASSIST_ENABLED = "FEATURE_ASSIST_ENABLED",
  FEATURE_ASSIST_TRAVEL = "FEATURE_ASSIST_TRAVEL",
  FEATURE_ASSIST_CATCHUP = "FEATURE_ASSIST_CATCHUP",
  FEATURE_ASSIST_CATCHUP_AM = "FEATURE_ASSIST_CATCHUP_AM",
  FEATURE_ASSIST_CATCHUP_PM = "FEATURE_ASSIST_CATCHUP_PM",
  FEATURE_ASSIST_LUNCH = "FEATURE_ASSIST_LUNCH",
  FEATURE_ASSIST_FOCUS = "FEATURE_ASSIST_FOCUS",
  FEATURE_ASSIST_CONFERENCE_BUFFER = "FEATURE_ASSIST_CONFERENCE_BUFFER",
  FEATURE_PERSONAL_SYNC_WORKING_HOURS = "FEATURE_PERSONAL_SYNC_WORKING_HOURS",
  FEATURE_PERSONAL_SYNC_TRANSPARENCY = "FEATURE_PERSONAL_SYNC_TRANSPARENCY",
  FEATURE_SLACK_ENABLED = "FEATURE_SLACK_ENABLED",
  FEATURE_SLACK_STATUS_SYNC = "FEATURE_SLACK_STATUS_SYNC",
  FEATURE_TASKS_ENABLED = "FEATURE_TASKS_ENABLED",
  FEATURE_GOOGLE_TASKS_ENABLED = "FEATURE_GOOGLE_TASKS_ENABLED",
  FEATURE_PRIORITIES_ENABLED = "FEATURE_PRIORITIES_ENABLED",
  FEATURE_COLORS_ENABLED = "FEATURE_COLORS_ENABLED",
  FEATURE_CALENDAR_ENABLED = "FEATURE_CALENDAR_ENABLED",
  FEATURE_PROJECTS_ENABLED = "FEATURE_PROJECTS_ENABLED",
  INTEREST_TASKS = "INTEREST_TASKS",
  INTEREST_PRIORITIES = "INTEREST_PRIORITIES",
  INTEREST_CALENDAR = "INTEREST_CALENDAR",
  INTEREST_OFFICE365 = "INTEREST_OFFICE365",
  INTEREST_INTEGRATION_ASANA = "INTEREST_INTEGRATION_ASANA",
  INTEREST_INTEGRATION_TRELLO = "INTEREST_INTEGRATION_TRELLO",
  INTEREST_INTEGRATION_TODOIST = "INTEREST_INTEGRATION_TODOIST",
  INTEREST_INTEGRATION_JIRA = "INTEREST_INTEGRATION_JIRA",
  INTEREST_INTEGRATION_LINEAR = "INTEREST_INTEGRATION_LINEAR",
  ONBOARD_HABITS = "ONBOARD_HABITS",
  ONBOARD_TASKS = "ONBOARD_TASKS",
  ONBOARD_GOOGLE_TASKS = "ONBOARD_GOOGLE_TASKS",
  ONBOARD_PLAN_ITEM_PRIORITIZED = "ONBOARD_PLAN_ITEM_PRIORITIZED",
  ONBOARD_SMART_ONE_ON_ONES = "ONBOARD_SMART_ONE_ON_ONES",
  ONBOARD_BUFFER_TIME = "ONBOARD_BUFFER_TIME",
  REFERRALS_SIGNED_UP = "REFERRALS_SIGNED_UP",
  REFERRALS_ACTIVE = "REFERRALS_ACTIVE",
  REFERRALS_DELETED = "REFERRALS_DELETED",
  INVITES_SENT = "INVITES_SENT",
  INVITEES_SIGNED_UP = "INVITEES_SIGNED_UP",
  INVITEES_ACTIVE = "INVITEES_ACTIVE",
  INVITEES_DELETED = "INVITEES_DELETED",
  WEEKLY_REPORT_SUBSCRIBED = "WEEKLY_REPORT_SUBSCRIBED",
  ZOOM_ENABLED = "ZOOM_ENABLED",
}

export type ValueInsight = Insight & { value?: Object };

export type WebSocketRequestAction = object;

export interface WebSocketResponseEnvelope {
  data?: Object;
  delete?: boolean;
  type?: SubscriptionType;
  compressed?: boolean;
  subscriptionId?: string | null;
  notificationKeys?: string[];
}

export interface WeeklyReport {
  enabled?: boolean;
  sendReport?: boolean;
}

export interface WeeklyReportAgenda {
  firstRow?: WeeklyReportAgendaDay[];
  secondRow?: WeeklyReportAgendaDay[];
}

export interface WeeklyReportAgendaDay {
  day?: string;
  items?: string[];
  extraClass?: string;
}

export interface WeeklyReportCallout {
  text?: string;
  textColor?: string;
  backgroundColor?: string;
  icon?: string;
  svgIcon?: string;
}

export interface WeeklyReportHeadlineStats {
  personal?: TimeSpentDuringWeek;
  work?: TimeSpentDuringWeek;
  meetings?: TimeSpentDuringWeek;
}

export interface WeeklyReportSocialShare {
  id?: string;
  verificationKey?: string;
}

export interface WeeklyReportTask {
  name?: string;
  chip?: string;
  due?: string;
  timeLeft?: string;
}

export interface WeeklyReportTemplateData {
  user?: UserTemplateData;
  reportRange?: string;
  reportRangeEncoded?: string;
  stats?: WeeklyReportHeadlineStats;
  celebration?: string;
  callouts?: WeeklyReportCallout[];
  agenda?: WeeklyReportAgenda;
  tasks?: WeeklyReportTask[];
  baseUrl?: string;
  baseUrlEncoded?: string;
  social?: WeeklyReportSocialShare;
  unsubPayload?: string;
}

export interface WeeklyReportUnsubscribeRequest {
  id?: string;
  verificationKey?: string;
}

export interface WorkingHours {
  startOfWeek?: DayOfWeek;
  days?: DayOfWeek[];
  start?: LocalTime;
  end?: LocalTime;
  duration?: Duration;
}

export interface ZoneId {
  id?: string;
  rules?: ZoneRules;
}

export type ZoneOffset = ZoneId & { totalSeconds?: number; id?: string; rules?: ZoneRules };

export interface ZoneOffsetTransition {
  instant?: string;
  dateTimeBefore?: string;
  dateTimeAfter?: string;
  offsetBefore?: ZoneOffset;
  offsetAfter?: ZoneOffset;
  duration?: Duration;
  gap?: boolean;
  overlap?: boolean;
}

export interface ZoneOffsetTransitionRule {
  month?: Month;
  dayOfMonthIndicator?: number;
  dayOfWeek?: DayOfWeek;
  localTime?: LocalTime;
  midnightEndOfDay?: boolean;
  timeDefinition?: ZoneOffsetTransitionRuleTimeDefinition;
  standardOffset?: ZoneOffset;
  offsetBefore?: ZoneOffset;
  offsetAfter?: ZoneOffset;
}

export enum ZoneOffsetTransitionRuleTimeDefinition {
  UTC = "UTC",
  WALL = "WALL",
  STANDARD = "STANDARD",
}

export interface ZoneRules {
  fixedOffset?: boolean;
  transitions?: ZoneOffsetTransition[];
  transitionRules?: ZoneOffsetTransitionRule[];
}

/**
 * "id" - "BXO-3EI3SuWprvWMeH9hJg"
 "first_name" - "Saxon"
 "last_name" - "D'Aubin"
 "email" - "saxon"type" - 1
 "role_name" - "Owner"
 "pmi" - 3549027672
 "use_pmi" - false
 "personal_meeting_url" - "https://zoom.us/j/3549027672?pwd=ZHhlQ0JqV1VKUjAyTUZIMThCaG5KQT09"
 "timezone" - "America/Los_Angeles"
 "verified" - 0
 "dept" - ""
 "created_at" - "2020-09-07T17:11:09Z"
 "last_login_time" - "2021-06-22T15:00:36Z"
 "pic_url" - "https://lh3.googleusercontent.com/a-/AOh14Gh0HvDqujmY4W-i0vPThmxeAPwGIZ9PSJA7QgT9=s96-c"
 "host_key" - "477544"
 "cms_user_id" - ""
 "jid" - "bxo-3ei3suwprvwmeh9hjg"group_ids" -  size = 0
 "im_group_ids" -  size = 0
 "account_id" - "S8xCFinLQTqP_-tevwUhgw"
 "language" - "en-US"
 "phone_country" - ""
 "phone_number" - ""
 "status" - "active"
 "job_title" - ""
 "location" - ""
 "login_types" -  size = 1
 "role_id" - "0"
 */
export interface ZoomUser {
  id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
}

export type Void = object;

export namespace accounts {
  /**
   * @tags accounts
   * @name list
   * @request GET:/accounts
   */
  export namespace List {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags accounts
   * @name main
   * @request GET:/accounts/main
   */
  export namespace Main {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = ConnectedAccount;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags accounts
   * @name validateAll
   * @request POST:/accounts/validate
   */
  export namespace ValidateAll {
    export type RequestQuery = {};
    export type RequestBody = AbstractLinearTeam;
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags accounts
   * @name delete
   * @request DELETE:/accounts/{id}
   */
  export namespace Delete {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags accounts
   * @name validate
   * @request POST:/accounts/{id}/validate
   */
  export namespace Validate {
    export type RequestQuery = {};
    export type RequestBody = AbstractLinearTeam;
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace assist {
  /**
   * @tags assist
   * @name getDailyHabits
   * @request GET:/assist/habits/daily
   */
  export namespace GetDailyHabits {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = DailyHabit[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags assist
   * @name create
   * @request POST:/assist/habits/daily
   */
  export namespace Create {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = DailyHabit;
    export type ResponseBody = DailyHabit[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags assist
   * @name getDailyHabit
   * @request GET:/assist/habits/daily/{id}
   */
  export namespace GetDailyHabit {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = DailyHabit;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags assist
   * @name update
   * @request PUT:/assist/habits/daily/{id}
   */
  export namespace Update {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = DailyHabit;
    export type ResponseBody = DailyHabit[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags assist
   * @name delete
   * @request DELETE:/assist/habits/daily/{id}
   */
  export namespace Delete {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = never;
    export type ResponseBody = object | null;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags assist
   * @name patch
   * @request PATCH:/assist/habits/daily/{id}
   */
  export namespace Patch {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = DailyHabit;
    export type ResponseBody = DailyHabit[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags internal, assist
   * @name createDefaultHabits
   * @request POST:/assist/habits/defaults
   */
  export namespace CreateDefaultHabits {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = Record<string, boolean>;
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace calendars {
  /**
   * @tags calendars
   * @name getAllPersonal
   * @request GET:/calendars/personal
   */
  export namespace GetAllPersonal {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Calendar[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendars
   * @name createPersonal
   * @request POST:/calendars/personal
   */
  export namespace CreatePersonal {
    export type RequestQuery = {};
    export type RequestBody = { calendar?: Calendar };
    export type ResponseBody = Calendar;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendars
   * @name getPersonalCandidates
   * @request GET:/calendars/personal/candidates
   */
  export namespace GetPersonalCandidates {
    export type RequestQuery = { credentialId?: number | null };
    export type RequestBody = never;
    export type ResponseBody = Calendar[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendars
   * @name getPersonal
   * @request GET:/calendars/personal/{id}
   */
  export namespace GetPersonal {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Calendar;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendars
   * @name deletePersonal
   * @request DELETE:/calendars/personal/{id}
   */
  export namespace DeletePersonal {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = object | null;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendars
   * @name getPrimary
   * @request GET:/calendars/primary
   */
  export namespace GetPrimary {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Calendar;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendars
   * @name share
   * @request POST:/calendars/share
   */
  export namespace Share {
    export type RequestQuery = { credentialId: number };
    export type RequestBody = AbstractLinearTeam;
    export type ResponseBody = object | null;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendars
   * @name getAllSync
   * @request GET:/calendars/sync
   */
  export namespace GetAllSync {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Calendar[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendars
   * @name createSync
   * @request POST:/calendars/sync
   */
  export namespace CreateSync {
    export type RequestQuery = {};
    export type RequestBody = { calendar?: Calendar };
    export type ResponseBody = Calendar;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendarSyncPolicy
   * @name getSyncPolicies
   * @request GET:/calendars/sync-policy
   */
  export namespace GetSyncPolicies {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = CalendarSyncPolicy[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendarSyncPolicy
   * @name createSyncPolicy
   * @request POST:/calendars/sync-policy
   */
  export namespace CreateSyncPolicy {
    export type RequestQuery = {};
    export type RequestBody = { calendarSyncPolicy?: CalendarSyncPolicy };
    export type ResponseBody = CalendarSyncPolicy;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendarSyncPolicy
   * @name getSyncPolicy
   * @request GET:/calendars/sync-policy/{sourceId}/to/{targetId}
   */
  export namespace GetSyncPolicy {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = CalendarSyncPolicy;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendarSyncPolicy
   * @name deleteSyncPolicy
   * @request DELETE:/calendars/sync-policy/{sourceId}/to/{targetId}
   */
  export namespace DeleteSyncPolicy {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = object | null;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendarSyncPolicy
   * @name patchSyncPolicy
   * @request PATCH:/calendars/sync-policy/{sourceId}/to/{targetId}
   */
  export namespace PatchSyncPolicy {
    export type RequestQuery = {};
    export type RequestBody = CalendarSyncPolicy;
    export type ResponseBody = CalendarSyncPolicy;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendars
   * @name getSyncCandidates
   * @request GET:/calendars/sync/candidates
   */
  export namespace GetSyncCandidates {
    export type RequestQuery = { credentialId?: number | null };
    export type RequestBody = never;
    export type ResponseBody = Calendar[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendarSyncEvents
   * @name getSyncEventsForUser
   * @request GET:/calendars/sync/events
   * @secure
   */
  export namespace GetSyncEventsForUser {
    export type RequestQuery = { limit: number };
    export type RequestBody = never;
    export type ResponseBody = SyncEvent[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendarSyncEvents
   * @name getSyncEventsForCalendar
   * @request GET:/calendars/sync/events/{calendarId}
   * @secure
   */
  export namespace GetSyncEventsForCalendar {
    export type RequestQuery = { limit: number };
    export type RequestBody = never;
    export type ResponseBody = SyncEvent[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendarSyncEvents
   * @name getSyncEventsForPolicy
   * @request GET:/calendars/sync/events/{sourceId}/to/{targetId}
   * @secure
   */
  export namespace GetSyncEventsForPolicy {
    export type RequestQuery = { limit: number };
    export type RequestBody = never;
    export type ResponseBody = SyncEvent[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendars
   * @name interest
   * @request POST:/calendars/sync/interest
   */
  export namespace Interest {
    export type RequestQuery = { type: string };
    export type RequestBody = AbstractLinearTeam;
    export type ResponseBody = object | null;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendars
   * @name getSourceCandidates
   * @request GET:/calendars/sync/{credentialId}/candidates/sources
   */
  export namespace GetSourceCandidates {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Calendar[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendars
   * @name getTargetCandidates
   * @request GET:/calendars/sync/{credentialId}/candidates/targets
   */
  export namespace GetTargetCandidates {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Calendar[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendars
   * @name getSync
   * @request GET:/calendars/sync/{id}
   */
  export namespace GetSync {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Calendar;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendars
   * @name deleteSync
   * @request DELETE:/calendars/sync/{id}
   */
  export namespace DeleteSync {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = object | null;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendars
   * @name patchSync
   * @request PATCH:/calendars/sync/{id}
   */
  export namespace PatchSync {
    export type RequestQuery = {};
    export type RequestBody = Calendar;
    export type ResponseBody = Calendar;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendars
   * @name watch
   * @request POST:/calendars/watch
   */
  export namespace Watch {
    export type RequestQuery = {};
    export type RequestBody = AbstractLinearTeam;
    export type ResponseBody = LocalTime;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendars
   * @name listWatch
   * @summary Heads up! We have seen at least one case where Google can issue an excessive, unnatural amount of watch callbacks. See the debounce comment in CalendarListWatchJob
 for more information. Specifically, BE CAREFUL adding any blocking IO code, such as web service calls or database queries, upstream of that job.
   * @request POST:/calendars/watchList
   * @description Heads up! We have seen at least one case where Google can issue an excessive, unnatural amount of watch callbacks. See the debounce comment in CalendarListWatchJob for more information. Specifically, BE CAREFUL adding any blocking IO code, such as web service calls or database queries, upstream of that job.
   */
  export namespace ListWatch {
    export type RequestQuery = {};
    export type RequestBody = AbstractLinearTeam;
    export type ResponseBody = LocalTime;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendars
   * @name settingsWatch
   * @request POST:/calendars/watchSettings
   */
  export namespace SettingsWatch {
    export type RequestQuery = {};
    export type RequestBody = AbstractLinearTeam;
    export type ResponseBody = LocalTime;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace credentials {
  /**
   * @tags credentials
   * @name list
   * @request GET:/credentials
   * @secure
   */
  export namespace List {
    export type RequestQuery = { includeInvalid?: boolean | null };
    export type RequestBody = never;
    export type ResponseBody = Credential[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags credentials
   * @name listPersonal
   * @request GET:/credentials/personal
   * @secure
   */
  export namespace ListPersonal {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Credential[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags credentials
   * @name getPrimary
   * @request GET:/credentials/primary
   * @secure
   */
  export namespace GetPrimary {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Calendar;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags credentials
   * @name get
   * @request GET:/credentials/{id}
   * @secure
   */
  export namespace Get {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Credential;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags credentials
   * @name delete
   * @request DELETE:/credentials/{id}
   */
  export namespace Delete {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = object | null;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace events {
  /**
   * @tags events
   * @name query
   * @request GET:/events
   */
  export namespace Query {
    export type RequestQuery = {
      calendar?: number | null;
      priorities?: number[] | null | null;
      type?: EventType[] | null | null;
      smurf?: Smurf[] | null | null;
      project?: number[] | null | null;
      start?: string | null;
      end?: string | null;
      sourceDetails?: boolean | null;
      thin?: boolean | null;
      includeProjects?: ProjectInclude;
    };
    export type RequestBody = never;
    export type ResponseBody = Event[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags events
   * @name getAssist
   * @request GET:/events/assist
   */
  export namespace GetAssist {
    export type RequestQuery = { start?: string | null; end?: string | null; limit?: number | null };
    export type RequestBody = never;
    export type ResponseBody = Event[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags events
   * @name getPersonal
   * @request GET:/events/personal
   */
  export namespace GetPersonal {
    export type RequestQuery = { start?: string | null; end?: string | null; limit?: number | null };
    export type RequestBody = never;
    export type ResponseBody = Event[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags events
   * @name sendTestEvent
   * @request POST:/events/test
   */
  export namespace SendTestEvent {
    export type RequestQuery = {};
    export type RequestBody = { user?: User };
    export type ResponseBody = LocalTime;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags events
   * @name gcalViewRedirect
   * @request GET:/events/view/{calendarId}/{eventId}
   */
  export namespace GcalViewRedirect {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags events
   * @name getForCalendar
   * @request GET:/events/{calendarId}/{eventId}
   */
  export namespace GetForCalendar {
    export type RequestQuery = {
      sourceDetails?: boolean | null;
      thin?: boolean | null;
      includeProjects?: ProjectInclude;
    };
    export type RequestBody = never;
    export type ResponseBody = Event;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags events
   * @name patch
   * @request PATCH:/events/{calendarId}/{eventId}
   */
  export namespace Patch {
    export type RequestQuery = {
      sourceDetails?: boolean | null;
      thin?: boolean | null;
      includeProjects?: ProjectInclude;
      notificationKey?: string | null;
    };
    export type RequestBody = AbstractLinearTeam;
    export type ResponseBody = Event;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags events
   * @name adjustConferenceBuffer
   * @request POST:/events/{calendarId}/{eventId}/buffer
   */
  export namespace AdjustConferenceBuffer {
    export type RequestQuery = { duration: number; notificationKey?: string | null };
    export type RequestBody = AbstractLinearTeam;
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags events
   * @name adjustTravelTime
   * @request POST:/events/{calendarId}/{eventId}/travel/{type}
   */
  export namespace AdjustTravelTime {
    export type RequestQuery = { duration: number; notificationKey?: string | null };
    export type RequestBody = AbstractLinearTeam;
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags events
   * @name get
   * @request GET:/events/{eventId}
   */
  export namespace Get {
    export type RequestQuery = {
      sourceDetails?: boolean | null;
      thin?: boolean | null;
      includeProjects?: ProjectInclude;
    };
    export type RequestBody = never;
    export type ResponseBody = Event;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace habits {
  /**
   * @tags habits
   * @name autoReschedule
   * @request POST:/habits/{calendarId}/{eventId}/auto-reschedule
   */
  export namespace AutoReschedule {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = { user?: User };
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags habits
   * @name done
   * @request POST:/habits/{calendarId}/{eventId}/done
   */
  export namespace Done {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = { user?: User };
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags habits
   * @name extend
   * @request POST:/habits/{calendarId}/{eventId}/extend
   */
  export namespace Extend {
    export type RequestQuery = { timeChunks: number; notificationKey?: string | null };
    export type RequestBody = { user?: User };
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags habits
   * @name reschedule
   * @request POST:/habits/{calendarId}/{eventId}/reschedule
   */
  export namespace Reschedule {
    export type RequestQuery = { start: string; end: string; notificationKey?: string | null };
    export type RequestBody = { user?: User };
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags habits
   * @name restart
   * @request POST:/habits/{calendarId}/{eventId}/restart
   */
  export namespace Restart {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = { user?: User };
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags habits
   * @name skip
   * @request POST:/habits/{calendarId}/{eventId}/skip
   */
  export namespace Skip {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = { user?: User };
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags habits
   * @name snooze
   * @request POST:/habits/{calendarId}/{eventId}/snooze
   */
  export namespace Snooze {
    export type RequestQuery = { timeChunks: number; notificationKey?: string | null };
    export type RequestBody = { user?: User };
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags habits
   * @name start
   * @request POST:/habits/{calendarId}/{eventId}/start
   */
  export namespace Start {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = { user?: User };
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags habits
   * @name startNow
   * @request POST:/habits/{habitId}/startnow
   */
  export namespace StartNow {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = { user?: User };
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags habits
   * @name fetchAlternativeTimes
   * @summary Experimental - not in use (for now)... copied from Slack controller
   * @request GET:/habits/{habitId}/{calendarId}/{eventId}/alternatives
   * @secure
   * @description Experimental - not in use (for now)... copied from Slack controller
   */
  export namespace FetchAlternativeTimes {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace insights {
  /**
   * @tags insights
   * @name assistant
   * @request GET:/insights/assistant
   * @secure
   */
  export namespace Assistant {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Insights;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags insights
   * @name basic
   * @request GET:/insights/basic
   * @secure
   */
  export namespace Basic {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Insights;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags insights
   * @name budgetByCategory
   * @request GET:/insights/budget/categories
   * @secure
   */
  export namespace BudgetByCategory {
    export type RequestQuery = { start?: string | null; end?: string | null };
    export type RequestBody = never;
    export type ResponseBody = Insights;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace integrations {
  /**
   * @name getZoomIntegration
   * @request GET:/integrations/zoom
   * @secure
   */
  export namespace GetZoomIntegration {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = ZoomUser;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @name deleteZoomToken
   * @request DELETE:/integrations/zoom
   */
  export namespace DeleteZoomToken {
    export type RequestQuery = {};
    export type RequestBody = { user?: User };
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace linear {
  /**
   * @tags linear, integration, private
   * @name integrations
   * @request GET:/linear/integrations
   */
  export namespace Integrations {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = LinearIntegration[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags linear, integration, private
   * @name deleteIntegration
   * @request DELETE:/linear/integrations/{id}
   */
  export namespace DeleteIntegration {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags linear, integration, private
   * @name patchIntegration
   * @request PATCH:/linear/integrations/{id}
   */
  export namespace PatchIntegration {
    export type RequestQuery = {};
    export type RequestBody = LinearIntegrationPatch;
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags linear, integration, private
   * @name issueDetails
   * @request GET:/linear/issue/{id}
   */
  export namespace IssueDetails {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = LinearTaskDetails;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace oneOnOne {
  /**
   * @tags oneOnOnes
   * @name getOneOnOnes
   * @request GET:/oneOnOne
   */
  export namespace GetOneOnOnes {
    export type RequestQuery = { instances?: boolean | null };
    export type RequestBody = never;
    export type ResponseBody = RecurringOneOnOne[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags oneOnOnes
   * @name createOnOnOne
   * @request POST:/oneOnOne
   */
  export namespace CreateOnOnOne {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = RecurringOneOnOne;
    export type ResponseBody = RecurringOneOnOne[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags oneOnOnes
   * @name attendeeBusyTime
   * @request GET:/oneOnOne/attendees/busyTime
   */
  export namespace AttendeeBusyTime {
    export type RequestQuery = { email: string };
    export type RequestBody = never;
    export type ResponseBody = LocalTimeInterval[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags oneOnOnes
   * @name resolveAttendee
   * @request GET:/oneOnOne/attendees/resolve
   */
  export namespace ResolveAttendee {
    export type RequestQuery = { email: string };
    export type RequestBody = never;
    export type ResponseBody = RecurringAssignmentAttendee;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags oneOnOnes
   * @name cancelGet
   * @request GET:/oneOnOne/cancel/{eventId}/{oneOnOneId}
   */
  export namespace CancelGet {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = never;
    export type ResponseBody = RecurringOneOnOne;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags oneOnOnes
   * @name cancel
   * @request POST:/oneOnOne/cancel/{eventId}/{oneOnOneId}
   */
  export namespace Cancel {
    export type RequestQuery = {};
    export type RequestBody = { notificationKey?: string | null };
    export type ResponseBody = RecurringOneOnOne;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags oneOnOnes
   * @name getDetected
   * @request GET:/oneOnOne/detected
   */
  export namespace GetDetected {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = RecurringOneOnOne[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags oneOnOnes
   * @name respond
   * @request POST:/oneOnOne/invite/{id}/respond
   */
  export namespace Respond {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = { status?: RecurringAssignmentAttendeeStatus };
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags oneOnOnes
   * @name getMeetingInvites
   * @request GET:/oneOnOne/invites
   */
  export namespace GetMeetingInvites {
    export type RequestQuery = { instances?: boolean | null };
    export type RequestBody = never;
    export type ResponseBody = RecurringOneOnOne[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags oneOnOnes
   * @name getMeetingInvite
   * @request GET:/oneOnOne/invites/{id}
   */
  export namespace GetMeetingInvite {
    export type RequestQuery = { inviteKey?: string | null; instances?: boolean | null };
    export type RequestBody = never;
    export type ResponseBody = RecurringOneOnOne;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags oneOnOnes
   * @name rescheduleGet
   * @request GET:/oneOnOne/reschedule/{eventId}
   */
  export namespace RescheduleGet {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = never;
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags oneOnOnes
   * @name reschedule
   * @request POST:/oneOnOne/reschedule/{eventId}
   */
  export namespace Reschedule {
    export type RequestQuery = {};
    export type RequestBody = { notificationKey?: string | null };
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags oneOnOnes
   * @name getSuggestions
   * @request GET:/oneOnOne/suggestions
   */
  export namespace GetSuggestions {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = RecurringOneOnOne[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags oneOnOnes
   * @name getOneOnOne
   * @request GET:/oneOnOne/{id}
   */
  export namespace GetOneOnOne {
    export type RequestQuery = { instances?: boolean | null };
    export type RequestBody = never;
    export type ResponseBody = RecurringOneOnOne;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags oneOnOnes
   * @name deleteOneOnOne
   * @request DELETE:/oneOnOne/{id}
   */
  export namespace DeleteOneOnOne {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = never;
    export type ResponseBody = object | null;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags oneOnOnes
   * @name patchOneOnOne
   * @request PATCH:/oneOnOne/{id}
   */
  export namespace PatchOneOnOne {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = AbstractLinearTeam;
    export type ResponseBody = RecurringOneOnOne;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags oneOnOnes
   * @name getInstances
   * @request GET:/oneOnOne/{oneOnOneId}/instances
   * @secure
   */
  export namespace GetInstances {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = RecurringAssignmentInstance[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace people {
  /**
   * @tags people
   * @name search
   * @request GET:/people
   * @secure
   */
  export namespace Search {
    export type RequestQuery = { q: string; limit: number };
    export type RequestBody = never;
    export type ResponseBody = ThinPerson[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags private, people
   * @name unsubscribe
   * @request DELETE:/people/subscriptions
   */
  export namespace Unsubscribe {
    export type RequestQuery = { email: string; type: string };
    export type RequestBody = never;
    export type ResponseBody = object | null;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace planner {
  /**
   * @tags actions
   * @name pullTaskToCurrentWeek
   * @request POST:/planner/pull/task/{taskId}
   */
  export namespace PullTaskToCurrentWeek {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = AbstractLinearTeam;
    export type ResponseBody = Task;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags actions
   * @name pushToWeek
   * @request POST:/planner/push/task/{taskId}/{weekOf}
   */
  export namespace PushToWeek {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = AbstractLinearTeam;
    export type ResponseBody = Task;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags actions
   * @name snoozeTask
   * @request POST:/planner/snooze/task/{eventId}/{minutes}
   */
  export namespace SnoozeTask {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = AbstractLinearTeam;
    export type ResponseBody = Task;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace priorities {
  /**
   * @tags priorities
   * @name getPriorities
   * @request GET:/priorities
   * @secure
   */
  export namespace GetPriorities {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Priority[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags priorities
   * @name setPriorities
   * @request POST:/priorities
   */
  export namespace SetPriorities {
    export type RequestQuery = {};
    export type RequestBody = { user?: User };
    export type ResponseBody = Priority[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags priorities
   * @name get
   * @request GET:/priorities/{id}
   * @secure
   */
  export namespace Get {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Priority;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags priorities
   * @name put
   * @request PUT:/priorities/{id}
   */
  export namespace Put {
    export type RequestQuery = {};
    export type RequestBody = Priority & { user?: User };
    export type ResponseBody = object | null;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags priorities
   * @name delete
   * @request DELETE:/priorities/{id}
   */
  export namespace Delete {
    export type RequestQuery = {};
    export type RequestBody = { user?: User };
    export type ResponseBody = object | null;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace projects {
  /**
   * @tags projects
   * @name query
   * @request GET:/projects
   */
  export namespace Query {
    export type RequestQuery = { parameters: object[] };
    export type RequestBody = never;
    export type ResponseBody = Project[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags projects
   * @name create
   * @request POST:/projects
   */
  export namespace Create {
    export type RequestQuery = {};
    export type RequestBody = Project;
    export type ResponseBody = Project;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags projects
   * @name get
   * @request GET:/projects/{id}
   */
  export namespace Get {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Project;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags projects
   * @name put
   * @request PUT:/projects/{id}
   */
  export namespace Put {
    export type RequestQuery = {};
    export type RequestBody = Project;
    export type ResponseBody = Project;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags projects
   * @name delete
   * @request DELETE:/projects/{id}
   */
  export namespace Delete {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags projects
   * @name patch
   * @request PATCH:/projects/{id}
   */
  export namespace Patch {
    export type RequestQuery = {};
    export type RequestBody = Project;
    export type ResponseBody = Project;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags projects
   * @name getEvents
   * @request GET:/projects/{id}/events
   */
  export namespace GetEvents {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = EventKey[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags projects
   * @name getTasks
   * @request GET:/projects/{id}/tasks
   */
  export namespace GetTasks {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = number[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags projects
   * @name associateEvent
   * @request POST:/projects/{projectId}/events/{calendarId}/{eventId}
   */
  export namespace AssociateEvent {
    export type RequestQuery = {};
    export type RequestBody = AbstractLinearTeam;
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags projects
   * @name disassociateEvent
   * @request DELETE:/projects/{projectId}/events/{calendarId}/{eventId}
   */
  export namespace DisassociateEvent {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags projects
   * @name associateTask
   * @request POST:/projects/{projectId}/tasks/{taskId}
   */
  export namespace AssociateTask {
    export type RequestQuery = {};
    export type RequestBody = AbstractLinearTeam;
    export type ResponseBody = any;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags projects
   * @name disassociateTask
   * @request DELETE:/projects/{projectId}/tasks/{taskId}
   */
  export namespace DisassociateTask {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = any;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace schedule {
  /**
   * @tags schedule
   * @name query
   * @request GET:/schedule
   * @secure
   */
  export namespace Query {
    export type RequestQuery = { q: string; start?: string | null; end?: string | null; limit?: number | null };
    export type RequestBody = never;
    export type ResponseBody = PlanItem[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags schedule
   * @name expandedPlan
   * @request GET:/schedule/expanded-plan
   * @secure
   */
  export namespace ExpandedPlan {
    export type RequestQuery = { start?: string | null; end?: string | null };
    export type RequestBody = never;
    export type ResponseBody = ExpandedPlan;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace slack {
  /**
   * @tags slack, integration, private
   * @name actionEndpoint
   * @request POST:/slack/action-endpoint
   */
  export namespace ActionEndpoint {
    export type RequestQuery = {};
    export type RequestBody = AbstractLinearTeam;
    export type ResponseBody = object | null;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags slack, integration, private
   * @name slackRedirect
   * @request GET:/slack/add
   */
  export namespace SlackRedirect {
    export type RequestQuery = { scope?: string | null; bot?: string | null; teamId?: string | null };
    export type RequestBody = never;
    export type ResponseBody = object | null;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags slack, integration, private
   * @name command
   * @request POST:/slack/command
   */
  export namespace Command {
    export type RequestQuery = {};
    export type RequestBody = {
      command?: string | null;
      text?: string | null;
      response_url?: string | null;
      trigger_id?: string | null;
      user_id?: string | null;
      team_id?: string | null;
      enterprise_id?: string | null;
      channel_id?: string | null;
      ssl_check?: string | null;
    };
    export type ResponseBody = object | null;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags slack, integration, private
   * @name connect
   * @request GET:/slack/connect
   */
  export namespace Connect {
    export type RequestQuery = { code?: string | null; error?: string | null };
    export type RequestBody = never;
    export type ResponseBody = object | null;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags slack, integration, private
   * @name interactiveEndpoint
   * @request POST:/slack/interactive-endpoint
   */
  export namespace InteractiveEndpoint {
    export type RequestQuery = {};
    export type RequestBody = { response_url?: string | null; payload?: string | null };
    export type ResponseBody = object | null;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags slack, integration, private
   * @name link
   * @request POST:/slack/link
   */
  export namespace Link {
    export type RequestQuery = { teamId: string; userId: string };
    export type RequestBody = { user?: User };
    export type ResponseBody = object | null;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags slack, integration, private
   * @name optionsLoadEndpoint
   * @request POST:/slack/options-load-endpoint
   */
  export namespace OptionsLoadEndpoint {
    export type RequestQuery = {};
    export type RequestBody = { response_url?: string | null; payload?: string | null };
    export type ResponseBody = object | null;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace tasks {
  /**
   * @tags tasks
   * @name query
   * @request GET:/tasks
   * @secure
   */
  export namespace Query {
    export type RequestQuery = {
      status?: TaskStatus[] | null | null;
      project?: number | null;
      priority?: Smurf;
      id?: number[] | null | null;
      instances?: boolean | null;
      includeProjects?: ProjectInclude;
    };
    export type RequestBody = never;
    export type ResponseBody = Task[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags tasks
   * @name create
   * @request POST:/tasks
   */
  export namespace Create {
    export type RequestQuery = {
      instances?: boolean | null;
      includeProjects?: ProjectInclude;
      notificationKey?: string | null;
    };
    export type RequestBody = Task & { user?: User };
    export type ResponseBody = Task;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags tasks
   * @name interest
   * @request POST:/tasks/interest
   */
  export namespace Interest {
    export type RequestQuery = {};
    export type RequestBody = { user?: User };
    export type ResponseBody = object | null;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags tasks
   * @name getTask
   * @request GET:/tasks/{id}
   * @secure
   */
  export namespace GetTask {
    export type RequestQuery = { instances?: boolean | null; includeProjects?: ProjectInclude };
    export type RequestBody = never;
    export type ResponseBody = Task;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags tasks
   * @name delete
   * @request DELETE:/tasks/{id}
   */
  export namespace Delete {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = never;
    export type ResponseBody = object | null;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags tasks
   * @name archive
   * @request POST:/tasks/{id}/archive
   */
  export namespace Archive {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = { user?: User };
    export type ResponseBody = Task;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags tasks
   * @name cancel
   * @request POST:/tasks/{id}/cancel
   */
  export namespace Cancel {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = { user?: User };
    export type ResponseBody = Task;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags tasks
   * @name done
   * @request POST:/tasks/{id}/done
   */
  export namespace Done {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = { user?: User };
    export type ResponseBody = Task;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags tasks
   * @name extend
   * @request POST:/tasks/{id}/extend
   */
  export namespace Extend {
    export type RequestQuery = { timeChunks: number; notificationKey?: string | null };
    export type RequestBody = { user?: User };
    export type ResponseBody = Task;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags tasks
   * @name start
   * @request POST:/tasks/{id}/start
   */
  export namespace Start {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = { user?: User };
    export type ResponseBody = Task;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags tasks
   * @name stop
   * @request POST:/tasks/{id}/stop
   */
  export namespace Stop {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = { user?: User };
    export type ResponseBody = Task;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags tasks
   * @name unarchive
   * @request POST:/tasks/{id}/unarchive
   */
  export namespace Unarchive {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = { user?: User };
    export type ResponseBody = Task;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags tasks
   * @name update
   * @request PUT:/tasks/{taskId}
   */
  export namespace Update {
    export type RequestQuery = {
      instances?: boolean | null;
      includeProjects?: ProjectInclude;
      notificationKey?: string | null;
    };
    export type RequestBody = Task & { user?: User };
    export type ResponseBody = Task;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags tasks
   * @name patch
   * @request PATCH:/tasks/{taskId}
   */
  export namespace Patch {
    export type RequestQuery = {
      instances?: boolean | null;
      includeProjects?: ProjectInclude;
      notificationKey?: string | null;
    };
    export type RequestBody = AbstractLinearTeam;
    export type ResponseBody = Task;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags tasks
   * @name getInstances
   * @request GET:/tasks/{taskId}/instances
   * @secure
   */
  export namespace GetInstances {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = TaskInstance[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags tasks
   * @name updateInstance
   * @request PATCH:/tasks/{taskId}/instances/{index}
   */
  export namespace UpdateInstance {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = { user?: User };
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace users {
  /**
   * @tags users
   * @name current
   * @summary User details for the currently authenticated user
   * @request GET:/users/current
   * @description User details for the currently authenticated user
   */
  export namespace Current {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = User;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags users
   * @name delete
   * @request DELETE:/users/current
   */
  export namespace Delete {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = object | null;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags users
   * @name patch
   * @request PATCH:/users/current
   */
  export namespace Patch {
    export type RequestQuery = {};
    export type RequestBody = User;
    export type ResponseBody = User;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags contacts
   * @name getContacts
   * @request GET:/users/current/contacts
   * @secure
   */
  export namespace GetContacts {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = ThinPerson[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags contacts
   * @name inviteContacts
   * @request POST:/users/current/contacts/invite
   */
  export namespace InviteContacts {
    export type RequestQuery = {};
    export type RequestBody = ThinPerson[];
    export type ResponseBody = object | null;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags users
   * @name referrals
   * @request GET:/users/current/referrals
   */
  export namespace Referrals {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = ReferralStats;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags users
   * @name interest
   * @request POST:/users/interest
   */
  export namespace Interest {
    export type RequestQuery = { feature: string };
    export type RequestBody = AbstractLinearTeam;
    export type ResponseBody = User;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags users
   * @name addTrait
   * @request POST:/users/trait/{trait}
   */
  export namespace AddTrait {
    export type RequestQuery = {};
    export type RequestBody = AbstractLinearTeam;
    export type ResponseBody = User;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace weeklyReport {
  /**
   * @tags weekly-report
   * @name social
   * @request GET:/weekly-report/social
   */
  export namespace Social {
    export type RequestQuery = { id: string; verificationKey?: string | null };
    export type RequestBody = never;
    export type ResponseBody = WeeklyReportTemplateData;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags weekly-report
   * @name socialImage
   * @request GET:/weekly-report/social/{id}.png
   */
  export namespace SocialImage {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags weekly-report
   * @name socialImageAlt
   * @request GET:/weekly-report/social/{trackingCode}/{suffix}.png
   */
  export namespace SocialImageAlt {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags weekly-report
   * @name unsubscribe
   * @request POST:/weekly-report/unsubscribe
   */
  export namespace Unsubscribe {
    export type RequestQuery = {};
    export type RequestBody = WeeklyReportUnsubscribeRequest;
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace avatar {
  /**
   * @name credential
   * @request GET:/avatar/credential/{credentialId}
   */
  export namespace Credential {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @name avatar
   * @request GET:/avatar/lookup
   */
  export namespace Avatar {
    export type RequestQuery = { email: string };
    export type RequestBody = never;
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @name me
   * @request GET:/avatar/me
   */
  export namespace Me {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace aws {
  /**
   * @tags aws-ses
   * @name sesEvent
   * @request POST:/aws/ses/events
   */
  export namespace SesEvent {
    export type RequestQuery = {};
    export type RequestBody = LocalTime;
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace oauth {
  /**
   * @name accountInit
   * @request GET:/oauth/zoom/account/init
   */
  export namespace AccountInit {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = object | null;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @name initZoom
   * @request GET:/oauth/zoom/init
   */
  export namespace InitZoom {
    export type RequestQuery = { state?: string | null };
    export type RequestBody = never;
    export type ResponseBody = object | null;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace ws {
  /**
   * @tags websocket
   * @name swaggerHelper
   * @summary This is a bogus endpoint that gives us swagger generated objects for our websocket specific classes.
   * @request POST:/ws/bogus
   * @description This is a bogus endpoint that gives us swagger generated objects for our websocket specific classes.
   */
  export namespace SwaggerHelper {
    export type RequestQuery = {};
    export type RequestBody = {
      user?: User;
      envelope?: WebSocketResponseEnvelope;
      assistCompleted?: AssistCompleted;
      assistPlanned?: AssistPlanned;
    };
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags websocket
   * @name connect
   * @summary How do we get the connectionId?

 # List all integrations

 aws apigatewayv2 get-integrations --api-id xxxxxxxxx

 # Update all integration

 aws apigatewayv2 update-integration --integration-id zzzzzzzz --api-id xxxxxxxxx --request-parameters 'integration.request.header.connectionId'='context.connectionId'
   * @request POST:/ws/connect
   * @description How do we get the connectionId? # List all integrations aws apigatewayv2 get-integrations --api-id xxxxxxxxx # Update all integration aws apigatewayv2 update-integration --integration-id zzzzzzzz --api-id xxxxxxxxx --request-parameters 'integration.request.header.connectionId'='context.connectionId'
   */
  export namespace Connect {
    export type RequestQuery = {};
    export type RequestBody = { user?: User };
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags websocket
   * @name defaultHandler
   * @request POST:/ws/default
   */
  export namespace DefaultHandler {
    export type RequestQuery = {};
    export type RequestBody = AbstractLinearTeam;
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags websocket
   * @name disconnect
   * @request POST:/ws/disconnect
   */
  export namespace Disconnect {
    export type RequestQuery = {};
    export type RequestBody = AbstractLinearTeam;
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags websocket
   * @name subscribe
   * @request POST:/ws/subscribe
   */
  export namespace Subscribe {
    export type RequestQuery = {};
    export type RequestBody = { subscribeAction?: SubscribeAction };
    export type ResponseBody = WebSocketResponseEnvelope;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags websocket
   * @name unsubscribe
   * @request POST:/ws/unsubscribe
   */
  export namespace Unsubscribe {
    export type RequestQuery = {};
    export type RequestBody = { unsubscribeAction?: UnsubscribeAction };
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}

export const HEADER_BUILD_ID = "X-Reclaim-Build-Id";

export type RequestParams = Omit<AxiosRequestConfig, "url" | "data" | "method"> & {
  secure?: boolean;
};

// TODO: The need for this is hard codded into the codegen source... so dumb
enum BodyType {
  Json,
}

export type RequestQueryParamsType = Record<string | number, any>;

export type ApiConfig<SecurityDataType> = {
  baseUrl?: string;
  baseApiParams?: RequestParams;
  securityWorker?: (securityData: SecurityDataType) => RequestParams;
};

/** Overrided Promise type. Needs for additional typings of `.catch` callback */
export type TypedErrorPromise<ResolveType, RejectType = unknown> = Omit<Promise<ResolveType>, "then" | "catch"> & {
  then<TResult1 = ResolveType, TResult2 = never>(
    onfulfilled?: ((value: ResolveType) => TResult1 | PromiseLike<TResult1>) | undefined | null,
    onrejected?: ((reason: RejectType) => TResult2 | PromiseLike<TResult2>) | undefined | null,
  ): Promise<TResult1 | TResult2>;
  catch<TResult = never>(
    onrejected?: ((reason: RejectType) => TResult | PromiseLike<TResult>) | undefined | null,
  ): Promise<ResolveType | TResult>;
  finally<TResult = never>(onfinally?: (() => void) | undefined | null): Promise<ResolveType | TResult>;
  readonly [Symbol.toStringTag]: string;
};

export class ReclaimApiError<
  E extends { status: number; response?: unknown | null } = { status: number; response?: unknown | null },
> extends Error {
  status: E["status"];
  statusText?: string;
  headers: unknown;
  body: E["response"];
  handled: boolean;

  constructor(res: AxiosResponse, message?: string) {
    super(message);

    if (!res && !message) message = "No response object";

    this.status = res?.status;
    this.statusText = res?.statusText || res.data?.message || message;
    this.headers = res?.headers;
    this.body = res?.data;
  }
}

export class NetworkError extends Error {
  status: "NetworkError" = "NetworkError";
  statusText: any;
  headers: any;
  body: any;
  handled: boolean;

  constructor(res: AxiosResponse, message?: string) {
    super(message);

    if (!res && !message) message = "No response object";

    this.statusText = res?.statusText || res.data?.message || message;
    this.headers = res?.headers;
    this.body = res?.data;
  }
}

// export type ClientConfig = ApiConfig<SecurityDataType,> & {BUILD_ID?: string}
export type ClientConfig = ApiConfig<any> & { BUILD_ID?: string };

class HttpClient<SecurityDataType> {
  public baseUrl: string = "https://api.app.reclaim.ai/api";
  public client: AxiosInstance;
  private securityData: SecurityDataType = null as any;
  private securityWorker: ApiConfig<SecurityDataType>["securityWorker"] = (() => {}) as any;
  private baseApiParams: RequestParams = {
    headers: {
      "Content-Type": "application/json",
      Referrer: "no-referrer",
    },
  };

  private requestHooks = {};
  private responseHooks = {};

  constructor({ baseUrl, baseApiParams, securityWorker, BUILD_ID }: ClientConfig = { BUILD_ID: "unknown" }) {
    if (!!baseUrl) this.baseUrl = baseUrl;
    if (!!baseApiParams) this.baseApiParams = baseApiParams;
    if (!!securityWorker) this.securityWorker = securityWorker;
    this.client = axios.create({
      baseURL: baseUrl || this.baseUrl,
      withCredentials: true,
      headers: {
        [HEADER_BUILD_ID]: BUILD_ID,
        ...(this.baseApiParams.headers || {}),
        post: {
          "Content-Type": "application/json; charset=utf-8",
        },
      },
    });
    this.client.interceptors.request.use((config) => {
      Object.keys(this.requestHooks).forEach((name) => {
        this.requestHooks[name](config);
      });

      return config;
    });

    this.client.interceptors.response.use(
      (r) => {
        Object.keys(this.responseHooks).forEach((name) => {
          if (r.status) {
            return this.responseHooks[`${name} - Status: ${r.status}`](new ReclaimApiError(r));
          }

          this.responseHooks[`${name} - Status: network`](new NetworkError(r));
        });
        return r;
      },
      (error) => {
        Object.keys(this.responseHooks).forEach((name) => {
          this.responseHooks[name](error);
        });
        return error;
      },
    );
  }

  public setSecurityData = (data: SecurityDataType) => {
    this.securityData = data;
  };

  private addQueryParam(query: RequestQueryParamsType, key: string) {
    return (
      encodeURIComponent(key) + "=" + encodeURIComponent(Array.isArray(query[key]) ? query[key].join(",") : query[key])
    );
  }

  protected addQueryParams(rawQuery?: RequestQueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys.length
      ? `?${keys
          .map((key) =>
            typeof query[key] === "object" && !Array.isArray(query[key])
              ? this.addQueryParams(query[key] as object).substring(1)
              : this.addQueryParam(query, key),
          )
          .join("&")}`
      : "";
  }

  public registerRequestHook(name: string, fn: (config: AxiosRequestConfig) => void | Promise<void>) {
    this.requestHooks[name] = fn;
  }

  public registerResponseHook(
    name: string,
    status: 401 | 500 | "NetworkError",
    fn: (error: any) => void | Promise<void>,
  ) {
    this.responseHooks[`${name} - Status: ${status}`] = fn;
  }

  public request = <T extends unknown, E extends { status: number; response?: unknown | null }>(
    path: string,
    method: AxiosRequestConfig["method"],
    { secure, ...params }: RequestParams = {},
    data?: any,
    _dumbUnusedBodyType?: any,
    secureByDefault?: boolean,
  ): TypedErrorPromise<T, ReclaimApiError<E> | NetworkError> =>
    this.client
      .request({
        url: path,
        ...params,
        method,
        data,
        validateStatus: function (status) {
          return true;
        },
      })
      .then((r) => {
        if (r.status >= 200 && r.status < 300) {
          return !!r.data ? r.data : null;
        } else if (!r.status) {
          throw new NetworkError(r);
        } else {
          throw new ReclaimApiError(r);
        }
      });
}

/**
 * @title Reclaim API
 * @version 0.1
 * @baseUrl https://api.app.reclaim.ai/api
 * Reclaim's awesome API
 */
export class ReclaimApi<SecurityDataType = any> extends HttpClient<SecurityDataType> {
  accounts = {
    /**
     * @tags accounts
     * @name list
     * @request GET:/accounts
     */
    list: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/accounts`,
        "GET",
        params,
      ),

    /**
     * @tags accounts
     * @name main
     * @request GET:/accounts/main
     */
    main: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<
        ConnectedAccount,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/accounts/main`, "GET", params),

    /**
     * @tags accounts
     * @name validateAll
     * @request POST:/accounts/validate
     */
    validateAll: <ErrorTypes extends { status: number; response: any }>(
      data: AbstractLinearTeam,
      params?: RequestParams,
    ) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/accounts/validate`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags accounts
     * @name delete
     * @request DELETE:/accounts/{id}
     */
    delete: <ErrorTypes extends { status: number; response: any }>(id: number, params?: RequestParams) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/accounts/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @tags accounts
     * @name validate
     * @request POST:/accounts/{id}/validate
     */
    validate: <ErrorTypes extends { status: number; response: any }>(
      id: number,
      data: AbstractLinearTeam,
      params?: RequestParams,
    ) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/accounts/${id}/validate`,
        "POST",
        params,
        data,
      ),
  };
  assist = {
    /**
     * @tags assist
     * @name getDailyHabits
     * @request GET:/assist/habits/daily
     */
    getDailyHabits: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<DailyHabit[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/assist/habits/daily`,
        "GET",
        params,
      ),

    /**
     * @tags assist
     * @name create
     * @request POST:/assist/habits/daily
     */
    create: <ErrorTypes extends { status: number; response: any }>(
      data: DailyHabit,
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<DailyHabit[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/assist/habits/daily${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags assist
     * @name getDailyHabit
     * @request GET:/assist/habits/daily/{id}
     */
    getDailyHabit: <ErrorTypes extends { status: number; response: any }>(id: number, params?: RequestParams) =>
      this.request<DailyHabit, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/assist/habits/daily/${id}`,
        "GET",
        params,
      ),

    /**
     * @tags assist
     * @name update
     * @request PUT:/assist/habits/daily/{id}
     */
    update: <ErrorTypes extends { status: number; response: any }>(
      id: number,
      data: DailyHabit,
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<DailyHabit[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/assist/habits/daily/${id}${this.addQueryParams(query)}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @tags assist
     * @name delete
     * @request DELETE:/assist/habits/daily/{id}
     */
    delete: <ErrorTypes extends { status: number; response: any }>(
      id: number,
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<object | null, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/assist/habits/daily/${id}${this.addQueryParams(query)}`,
        "DELETE",
        params,
      ),

    /**
     * @tags assist
     * @name patch
     * @request PATCH:/assist/habits/daily/{id}
     */
    patch: <ErrorTypes extends { status: number; response: any }>(
      id: number,
      data: DailyHabit,
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<DailyHabit[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/assist/habits/daily/${id}${this.addQueryParams(query)}`,
        "PATCH",
        params,
        data,
      ),

    /**
     * @tags internal, assist
     * @name createDefaultHabits
     * @request POST:/assist/habits/defaults
     */
    createDefaultHabits: <ErrorTypes extends { status: number; response: any }>(
      data: Record<string, boolean>,
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/assist/habits/defaults${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),
  };
  calendars = {
    /**
     * @tags calendars
     * @name getAllPersonal
     * @request GET:/calendars/personal
     */
    getAllPersonal: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<Calendar[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/calendars/personal`,
        "GET",
        params,
      ),

    /**
     * @tags calendars
     * @name createPersonal
     * @request POST:/calendars/personal
     */
    createPersonal: <ErrorTypes extends { status: number; response: any }>(
      data: { calendar?: Calendar },
      params?: RequestParams,
    ) =>
      this.request<Calendar, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/calendars/personal`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags calendars
     * @name getPersonalCandidates
     * @request GET:/calendars/personal/candidates
     */
    getPersonalCandidates: <ErrorTypes extends { status: number; response: any }>(
      query?: { credentialId?: number | null },
      params?: RequestParams,
    ) =>
      this.request<Calendar[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/calendars/personal/candidates${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags calendars
     * @name getPersonal
     * @request GET:/calendars/personal/{id}
     */
    getPersonal: <ErrorTypes extends { status: number; response: any }>(id: number, params?: RequestParams) =>
      this.request<Calendar, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/calendars/personal/${id}`,
        "GET",
        params,
      ),

    /**
     * @tags calendars
     * @name deletePersonal
     * @request DELETE:/calendars/personal/{id}
     */
    deletePersonal: <ErrorTypes extends { status: number; response: any }>(id: number, params?: RequestParams) =>
      this.request<object | null, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/calendars/personal/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @tags calendars
     * @name getPrimary
     * @request GET:/calendars/primary
     */
    getPrimary: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<Calendar, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/calendars/primary`,
        "GET",
        params,
      ),

    /**
     * @tags calendars
     * @name share
     * @request POST:/calendars/share
     */
    share: <ErrorTypes extends { status: number; response: any }>(
      query: { credentialId: number },
      data: AbstractLinearTeam,
      params?: RequestParams,
    ) =>
      this.request<object | null, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/calendars/share${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags calendars
     * @name getAllSync
     * @request GET:/calendars/sync
     */
    getAllSync: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<Calendar[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/calendars/sync`,
        "GET",
        params,
      ),

    /**
     * @tags calendars
     * @name createSync
     * @request POST:/calendars/sync
     */
    createSync: <ErrorTypes extends { status: number; response: any }>(
      data: { calendar?: Calendar },
      params?: RequestParams,
    ) =>
      this.request<Calendar, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/calendars/sync`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags calendarSyncPolicy
     * @name getSyncPolicies
     * @request GET:/calendars/sync-policy
     */
    getSyncPolicies: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<
        CalendarSyncPolicy[],
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/calendars/sync-policy`, "GET", params),

    /**
     * @tags calendarSyncPolicy
     * @name createSyncPolicy
     * @request POST:/calendars/sync-policy
     */
    createSyncPolicy: <ErrorTypes extends { status: number; response: any }>(
      data: { calendarSyncPolicy?: CalendarSyncPolicy },
      params?: RequestParams,
    ) =>
      this.request<
        CalendarSyncPolicy,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/calendars/sync-policy`, "POST", params, data),

    /**
     * @tags calendarSyncPolicy
     * @name getSyncPolicy
     * @request GET:/calendars/sync-policy/{sourceId}/to/{targetId}
     */
    getSyncPolicy: <ErrorTypes extends { status: number; response: any }>(
      sourceId: number,
      targetId: number,
      params?: RequestParams,
    ) =>
      this.request<
        CalendarSyncPolicy,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/calendars/sync-policy/${sourceId}/to/${targetId}`, "GET", params),

    /**
     * @tags calendarSyncPolicy
     * @name deleteSyncPolicy
     * @request DELETE:/calendars/sync-policy/{sourceId}/to/{targetId}
     */
    deleteSyncPolicy: <ErrorTypes extends { status: number; response: any }>(
      sourceId: number,
      targetId: number,
      params?: RequestParams,
    ) =>
      this.request<object | null, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/calendars/sync-policy/${sourceId}/to/${targetId}`,
        "DELETE",
        params,
      ),

    /**
     * @tags calendarSyncPolicy
     * @name patchSyncPolicy
     * @request PATCH:/calendars/sync-policy/{sourceId}/to/{targetId}
     */
    patchSyncPolicy: <ErrorTypes extends { status: number; response: any }>(
      sourceId: number,
      targetId: number,
      data: CalendarSyncPolicy,
      params?: RequestParams,
    ) =>
      this.request<
        CalendarSyncPolicy,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/calendars/sync-policy/${sourceId}/to/${targetId}`, "PATCH", params, data),

    /**
     * @tags calendars
     * @name getSyncCandidates
     * @request GET:/calendars/sync/candidates
     */
    getSyncCandidates: <ErrorTypes extends { status: number; response: any }>(
      query?: { credentialId?: number | null },
      params?: RequestParams,
    ) =>
      this.request<Calendar[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/calendars/sync/candidates${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags calendarSyncEvents
     * @name getSyncEventsForUser
     * @request GET:/calendars/sync/events
     * @secure
     */
    getSyncEventsForUser: <ErrorTypes extends { status: number; response: any }>(
      query: { limit: number },
      params?: RequestParams,
    ) =>
      this.request<SyncEvent[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/calendars/sync/events${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags calendarSyncEvents
     * @name getSyncEventsForCalendar
     * @request GET:/calendars/sync/events/{calendarId}
     * @secure
     */
    getSyncEventsForCalendar: <ErrorTypes extends { status: number; response: any }>(
      calendarId: number,
      query: { limit: number },
      params?: RequestParams,
    ) =>
      this.request<SyncEvent[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/calendars/sync/events/${calendarId}${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags calendarSyncEvents
     * @name getSyncEventsForPolicy
     * @request GET:/calendars/sync/events/{sourceId}/to/{targetId}
     * @secure
     */
    getSyncEventsForPolicy: <ErrorTypes extends { status: number; response: any }>(
      sourceId: number,
      targetId: number,
      query: { limit: number },
      params?: RequestParams,
    ) =>
      this.request<SyncEvent[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/calendars/sync/events/${sourceId}/to/${targetId}${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags calendars
     * @name interest
     * @request POST:/calendars/sync/interest
     */
    interest: <ErrorTypes extends { status: number; response: any }>(
      query: { type: string },
      data: AbstractLinearTeam,
      params?: RequestParams,
    ) =>
      this.request<object | null, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/calendars/sync/interest${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags calendars
     * @name getSourceCandidates
     * @request GET:/calendars/sync/{credentialId}/candidates/sources
     */
    getSourceCandidates: <ErrorTypes extends { status: number; response: any }>(
      credentialId: number,
      params?: RequestParams,
    ) =>
      this.request<Calendar[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/calendars/sync/${credentialId}/candidates/sources`,
        "GET",
        params,
      ),

    /**
     * @tags calendars
     * @name getTargetCandidates
     * @request GET:/calendars/sync/{credentialId}/candidates/targets
     */
    getTargetCandidates: <ErrorTypes extends { status: number; response: any }>(
      credentialId: number,
      params?: RequestParams,
    ) =>
      this.request<Calendar[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/calendars/sync/${credentialId}/candidates/targets`,
        "GET",
        params,
      ),

    /**
     * @tags calendars
     * @name getSync
     * @request GET:/calendars/sync/{id}
     */
    getSync: <ErrorTypes extends { status: number; response: any }>(id: number, params?: RequestParams) =>
      this.request<Calendar, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/calendars/sync/${id}`,
        "GET",
        params,
      ),

    /**
     * @tags calendars
     * @name deleteSync
     * @request DELETE:/calendars/sync/{id}
     */
    deleteSync: <ErrorTypes extends { status: number; response: any }>(id: number, params?: RequestParams) =>
      this.request<object | null, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/calendars/sync/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @tags calendars
     * @name patchSync
     * @request PATCH:/calendars/sync/{id}
     */
    patchSync: <ErrorTypes extends { status: number; response: any }>(
      id: number,
      data: Calendar,
      params?: RequestParams,
    ) =>
      this.request<Calendar, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/calendars/sync/${id}`,
        "PATCH",
        params,
        data,
      ),

    /**
     * @tags calendars
     * @name watch
     * @request POST:/calendars/watch
     */
    watch: <ErrorTypes extends { status: number; response: any }>(data: AbstractLinearTeam, params?: RequestParams) =>
      this.request<LocalTime, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/calendars/watch`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags calendars
     * @name listWatch
     * @summary Heads up! We have seen at least one case where Google can issue an excessive, unnatural amount of watch callbacks. See the debounce comment in CalendarListWatchJob
 for more information. Specifically, BE CAREFUL adding any blocking IO code, such as web service calls or database queries, upstream of that job.
     * @request POST:/calendars/watchList
     * @description Heads up! We have seen at least one case where Google can issue an excessive, unnatural amount of watch callbacks. See the debounce comment in CalendarListWatchJob for more information. Specifically, BE CAREFUL adding any blocking IO code, such as web service calls or database queries, upstream of that job.
     */
    listWatch: <ErrorTypes extends { status: number; response: any }>(
      data: AbstractLinearTeam,
      params?: RequestParams,
    ) =>
      this.request<LocalTime, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/calendars/watchList`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags calendars
     * @name settingsWatch
     * @request POST:/calendars/watchSettings
     */
    settingsWatch: <ErrorTypes extends { status: number; response: any }>(
      data: AbstractLinearTeam,
      params?: RequestParams,
    ) =>
      this.request<LocalTime, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/calendars/watchSettings`,
        "POST",
        params,
        data,
      ),
  };
  credentials = {
    /**
     * @tags credentials
     * @name list
     * @request GET:/credentials
     * @secure
     */
    list: <ErrorTypes extends { status: number; response: any }>(
      query?: { includeInvalid?: boolean | null },
      params?: RequestParams,
    ) =>
      this.request<Credential[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/credentials${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags credentials
     * @name listPersonal
     * @request GET:/credentials/personal
     * @secure
     */
    listPersonal: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<Credential[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/credentials/personal`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags credentials
     * @name getPrimary
     * @request GET:/credentials/primary
     * @secure
     */
    getPrimary: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<Calendar, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/credentials/primary`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags credentials
     * @name get
     * @request GET:/credentials/{id}
     * @secure
     */
    get: <ErrorTypes extends { status: number; response: any }>(id: number, params?: RequestParams) =>
      this.request<Credential, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/credentials/${id}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags credentials
     * @name delete
     * @request DELETE:/credentials/{id}
     */
    delete: <ErrorTypes extends { status: number; response: any }>(id: number, params?: RequestParams) =>
      this.request<object | null, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/credentials/${id}`,
        "DELETE",
        params,
      ),
  };
  events = {
    /**
     * @tags events
     * @name query
     * @request GET:/events
     */
    query: <ErrorTypes extends { status: number; response: any }>(
      query?: {
        calendar?: number | null;
        priorities?: number[] | null | null;
        type?: EventType[] | null | null;
        smurf?: Smurf[] | null | null;
        project?: number[] | null | null;
        start?: string | null;
        end?: string | null;
        sourceDetails?: boolean | null;
        thin?: boolean | null;
        includeProjects?: ProjectInclude;
      },
      params?: RequestParams,
    ) =>
      this.request<Event[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/events${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags events
     * @name getAssist
     * @request GET:/events/assist
     */
    getAssist: <ErrorTypes extends { status: number; response: any }>(
      query?: { start?: string | null; end?: string | null; limit?: number | null },
      params?: RequestParams,
    ) =>
      this.request<Event[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/events/assist${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags events
     * @name getPersonal
     * @request GET:/events/personal
     */
    getPersonal: <ErrorTypes extends { status: number; response: any }>(
      query?: { start?: string | null; end?: string | null; limit?: number | null },
      params?: RequestParams,
    ) =>
      this.request<Event[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/events/personal${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags events
     * @name sendTestEvent
     * @request POST:/events/test
     */
    sendTestEvent: <ErrorTypes extends { status: number; response: any }>(
      data: { user?: User },
      params?: RequestParams,
    ) =>
      this.request<LocalTime, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/events/test`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags events
     * @name gcalViewRedirect
     * @request GET:/events/view/{calendarId}/{eventId}
     */
    gcalViewRedirect: <ErrorTypes extends { status: number; response: any }>(
      calendarId: number,
      eventId: string,
      params?: RequestParams,
    ) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/events/view/${calendarId}/${eventId}`,
        "GET",
        params,
      ),

    /**
     * @tags events
     * @name getForCalendar
     * @request GET:/events/{calendarId}/{eventId}
     */
    getForCalendar: <ErrorTypes extends { status: number; response: any }>(
      calendarId: number,
      eventId: string,
      query?: { sourceDetails?: boolean | null; thin?: boolean | null; includeProjects?: ProjectInclude },
      params?: RequestParams,
    ) =>
      this.request<Event, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/events/${calendarId}/${eventId}${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags events
     * @name patch
     * @request PATCH:/events/{calendarId}/{eventId}
     */
    patch: <ErrorTypes extends { status: number; response: any }>(
      calendarId: number,
      eventId: string,
      data: AbstractLinearTeam,
      query?: {
        sourceDetails?: boolean | null;
        thin?: boolean | null;
        includeProjects?: ProjectInclude;
        notificationKey?: string | null;
      },
      params?: RequestParams,
    ) =>
      this.request<Event, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/events/${calendarId}/${eventId}${this.addQueryParams(query)}`,
        "PATCH",
        params,
        data,
      ),

    /**
     * @tags events
     * @name adjustConferenceBuffer
     * @request POST:/events/{calendarId}/{eventId}/buffer
     */
    adjustConferenceBuffer: <ErrorTypes extends { status: number; response: any }>(
      calendarId: number,
      eventId: string,
      query: { duration: number; notificationKey?: string | null },
      data: AbstractLinearTeam,
      params?: RequestParams,
    ) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/events/${calendarId}/${eventId}/buffer${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags events
     * @name adjustTravelTime
     * @request POST:/events/{calendarId}/{eventId}/travel/{type}
     */
    adjustTravelTime: <ErrorTypes extends { status: number; response: any }>(
      calendarId: number,
      eventId: string,
      type: AssistType,
      query: { duration: number; notificationKey?: string | null },
      data: AbstractLinearTeam,
      params?: RequestParams,
    ) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/events/${calendarId}/${eventId}/travel/${type}${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags events
     * @name get
     * @request GET:/events/{eventId}
     */
    get: <ErrorTypes extends { status: number; response: any }>(
      eventId: string,
      query?: { sourceDetails?: boolean | null; thin?: boolean | null; includeProjects?: ProjectInclude },
      params?: RequestParams,
    ) =>
      this.request<Event, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/events/${eventId}${this.addQueryParams(query)}`,
        "GET",
        params,
      ),
  };
  habits = {
    /**
     * @tags habits
     * @name autoReschedule
     * @request POST:/habits/{calendarId}/{eventId}/auto-reschedule
     */
    autoReschedule: <ErrorTypes extends { status: number; response: any }>(
      calendarId: number,
      eventId: string,
      data: { user?: User },
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/habits/${calendarId}/${eventId}/auto-reschedule${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags habits
     * @name done
     * @request POST:/habits/{calendarId}/{eventId}/done
     */
    done: <ErrorTypes extends { status: number; response: any }>(
      calendarId: number,
      eventId: string,
      data: { user?: User },
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/habits/${calendarId}/${eventId}/done${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags habits
     * @name extend
     * @request POST:/habits/{calendarId}/{eventId}/extend
     */
    extend: <ErrorTypes extends { status: number; response: any }>(
      calendarId: number,
      eventId: string,
      query: { timeChunks: number; notificationKey?: string | null },
      data: { user?: User },
      params?: RequestParams,
    ) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/habits/${calendarId}/${eventId}/extend${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags habits
     * @name reschedule
     * @request POST:/habits/{calendarId}/{eventId}/reschedule
     */
    reschedule: <ErrorTypes extends { status: number; response: any }>(
      calendarId: number,
      eventId: string,
      query: { start: string; end: string; notificationKey?: string | null },
      data: { user?: User },
      params?: RequestParams,
    ) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/habits/${calendarId}/${eventId}/reschedule${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags habits
     * @name restart
     * @request POST:/habits/{calendarId}/{eventId}/restart
     */
    restart: <ErrorTypes extends { status: number; response: any }>(
      calendarId: number,
      eventId: string,
      data: { user?: User },
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/habits/${calendarId}/${eventId}/restart${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags habits
     * @name skip
     * @request POST:/habits/{calendarId}/{eventId}/skip
     */
    skip: <ErrorTypes extends { status: number; response: any }>(
      calendarId: number,
      eventId: string,
      data: { user?: User },
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/habits/${calendarId}/${eventId}/skip${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags habits
     * @name snooze
     * @request POST:/habits/{calendarId}/{eventId}/snooze
     */
    snooze: <ErrorTypes extends { status: number; response: any }>(
      calendarId: number,
      eventId: string,
      query: { timeChunks: number; notificationKey?: string | null },
      data: { user?: User },
      params?: RequestParams,
    ) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/habits/${calendarId}/${eventId}/snooze${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags habits
     * @name start
     * @request POST:/habits/{calendarId}/{eventId}/start
     */
    start: <ErrorTypes extends { status: number; response: any }>(
      calendarId: number,
      eventId: string,
      data: { user?: User },
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/habits/${calendarId}/${eventId}/start${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags habits
     * @name startNow
     * @request POST:/habits/{habitId}/startnow
     */
    startNow: <ErrorTypes extends { status: number; response: any }>(
      habitId: number,
      data: { user?: User },
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/habits/${habitId}/startnow${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags habits
     * @name fetchAlternativeTimes
     * @summary Experimental - not in use (for now)... copied from Slack controller
     * @request GET:/habits/{habitId}/{calendarId}/{eventId}/alternatives
     * @secure
     * @description Experimental - not in use (for now)... copied from Slack controller
     */
    fetchAlternativeTimes: <ErrorTypes extends { status: number; response: any }>(
      habitId: number,
      calendarId: number,
      eventId: string,
      params?: RequestParams,
    ) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/habits/${habitId}/${calendarId}/${eventId}/alternatives`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),
  };
  insights = {
    /**
     * @tags insights
     * @name assistant
     * @request GET:/insights/assistant
     * @secure
     */
    assistant: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<Insights, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/insights/assistant`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags insights
     * @name basic
     * @request GET:/insights/basic
     * @secure
     */
    basic: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<Insights, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/insights/basic`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags insights
     * @name budgetByCategory
     * @request GET:/insights/budget/categories
     * @secure
     */
    budgetByCategory: <ErrorTypes extends { status: number; response: any }>(
      query?: { start?: string | null; end?: string | null },
      params?: RequestParams,
    ) =>
      this.request<Insights, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/insights/budget/categories${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),
  };
  integrations = {
    /**
     * @name getZoomIntegration
     * @request GET:/integrations/zoom
     * @secure
     */
    getZoomIntegration: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<ZoomUser, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/integrations/zoom`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @name deleteZoomToken
     * @request DELETE:/integrations/zoom
     */
    deleteZoomToken: <ErrorTypes extends { status: number; response: any }>(
      data: { user?: User },
      params?: RequestParams,
    ) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/integrations/zoom`,
        "DELETE",
        params,
        data,
      ),
  };
  linear = {
    /**
     * @tags linear, integration, private
     * @name integrations
     * @request GET:/linear/integrations
     */
    integrations: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<
        LinearIntegration[],
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/linear/integrations`, "GET", params),

    /**
     * @tags linear, integration, private
     * @name deleteIntegration
     * @request DELETE:/linear/integrations/{id}
     */
    deleteIntegration: <ErrorTypes extends { status: number; response: any }>(id: string, params?: RequestParams) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/linear/integrations/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @tags linear, integration, private
     * @name patchIntegration
     * @request PATCH:/linear/integrations/{id}
     */
    patchIntegration: <ErrorTypes extends { status: number; response: any }>(
      id: string,
      data: LinearIntegrationPatch,
      params?: RequestParams,
    ) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/linear/integrations/${id}`,
        "PATCH",
        params,
        data,
      ),

    /**
     * @tags linear, integration, private
     * @name issueDetails
     * @request GET:/linear/issue/{id}
     */
    issueDetails: <ErrorTypes extends { status: number; response: any }>(id: string, params?: RequestParams) =>
      this.request<
        LinearTaskDetails,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/linear/issue/${id}`, "GET", params),
  };
  oneOnOne = {
    /**
     * @tags oneOnOnes
     * @name getOneOnOnes
     * @request GET:/oneOnOne
     */
    getOneOnOnes: <ErrorTypes extends { status: number; response: any }>(
      query?: { instances?: boolean | null },
      params?: RequestParams,
    ) =>
      this.request<
        RecurringOneOnOne[],
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/oneOnOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags oneOnOnes
     * @name createOnOnOne
     * @request POST:/oneOnOne
     */
    createOnOnOne: <ErrorTypes extends { status: number; response: any }>(
      data: RecurringOneOnOne,
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<
        RecurringOneOnOne[],
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/oneOnOne${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags oneOnOnes
     * @name attendeeBusyTime
     * @request GET:/oneOnOne/attendees/busyTime
     */
    attendeeBusyTime: <ErrorTypes extends { status: number; response: any }>(
      query: { email: string },
      params?: RequestParams,
    ) =>
      this.request<
        LocalTimeInterval[],
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/oneOnOne/attendees/busyTime${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags oneOnOnes
     * @name resolveAttendee
     * @request GET:/oneOnOne/attendees/resolve
     */
    resolveAttendee: <ErrorTypes extends { status: number; response: any }>(
      query: { email: string },
      params?: RequestParams,
    ) =>
      this.request<
        RecurringAssignmentAttendee,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/oneOnOne/attendees/resolve${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags oneOnOnes
     * @name cancelGet
     * @request GET:/oneOnOne/cancel/{eventId}/{oneOnOneId}
     */
    cancelGet: <ErrorTypes extends { status: number; response: any }>(
      eventId: string,
      oneOnOneId: number,
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<
        RecurringOneOnOne,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/oneOnOne/cancel/${eventId}/${oneOnOneId}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags oneOnOnes
     * @name cancel
     * @request POST:/oneOnOne/cancel/{eventId}/{oneOnOneId}
     */
    cancel: <ErrorTypes extends { status: number; response: any }>(
      eventId: string,
      oneOnOneId: number,
      data: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<
        RecurringOneOnOne,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/oneOnOne/cancel/${eventId}/${oneOnOneId}`, "POST", params, data),

    /**
     * @tags oneOnOnes
     * @name getDetected
     * @request GET:/oneOnOne/detected
     */
    getDetected: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<
        RecurringOneOnOne[],
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/oneOnOne/detected`, "GET", params),

    /**
     * @tags oneOnOnes
     * @name respond
     * @request POST:/oneOnOne/invite/{id}/respond
     */
    respond: <ErrorTypes extends { status: number; response: any }>(
      id: number,
      data: { status?: RecurringAssignmentAttendeeStatus },
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/oneOnOne/invite/${id}/respond${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags oneOnOnes
     * @name getMeetingInvites
     * @request GET:/oneOnOne/invites
     */
    getMeetingInvites: <ErrorTypes extends { status: number; response: any }>(
      query?: { instances?: boolean | null },
      params?: RequestParams,
    ) =>
      this.request<
        RecurringOneOnOne[],
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/oneOnOne/invites${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags oneOnOnes
     * @name getMeetingInvite
     * @request GET:/oneOnOne/invites/{id}
     */
    getMeetingInvite: <ErrorTypes extends { status: number; response: any }>(
      id: number,
      query?: { inviteKey?: string | null; instances?: boolean | null },
      params?: RequestParams,
    ) =>
      this.request<
        RecurringOneOnOne,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/oneOnOne/invites/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags oneOnOnes
     * @name rescheduleGet
     * @request GET:/oneOnOne/reschedule/{eventId}
     */
    rescheduleGet: <ErrorTypes extends { status: number; response: any }>(
      eventId: string,
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/oneOnOne/reschedule/${eventId}${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags oneOnOnes
     * @name reschedule
     * @request POST:/oneOnOne/reschedule/{eventId}
     */
    reschedule: <ErrorTypes extends { status: number; response: any }>(
      eventId: string,
      data: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/oneOnOne/reschedule/${eventId}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags oneOnOnes
     * @name getSuggestions
     * @request GET:/oneOnOne/suggestions
     */
    getSuggestions: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<
        RecurringOneOnOne[],
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/oneOnOne/suggestions`, "GET", params),

    /**
     * @tags oneOnOnes
     * @name getOneOnOne
     * @request GET:/oneOnOne/{id}
     */
    getOneOnOne: <ErrorTypes extends { status: number; response: any }>(
      id: number,
      query?: { instances?: boolean | null },
      params?: RequestParams,
    ) =>
      this.request<
        RecurringOneOnOne,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/oneOnOne/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags oneOnOnes
     * @name deleteOneOnOne
     * @request DELETE:/oneOnOne/{id}
     */
    deleteOneOnOne: <ErrorTypes extends { status: number; response: any }>(
      id: number,
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<object | null, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/oneOnOne/${id}${this.addQueryParams(query)}`,
        "DELETE",
        params,
      ),

    /**
     * @tags oneOnOnes
     * @name patchOneOnOne
     * @request PATCH:/oneOnOne/{id}
     */
    patchOneOnOne: <ErrorTypes extends { status: number; response: any }>(
      id: number,
      data: AbstractLinearTeam,
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<
        RecurringOneOnOne,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/oneOnOne/${id}${this.addQueryParams(query)}`, "PATCH", params, data),

    /**
     * @tags oneOnOnes
     * @name getInstances
     * @request GET:/oneOnOne/{oneOnOneId}/instances
     * @secure
     */
    getInstances: <ErrorTypes extends { status: number; response: any }>(oneOnOneId: number, params?: RequestParams) =>
      this.request<
        RecurringAssignmentInstance[],
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/oneOnOne/${oneOnOneId}/instances`, "GET", params, null, BodyType.Json, true),
  };
  people = {
    /**
     * @tags people
     * @name search
     * @request GET:/people
     * @secure
     */
    search: <ErrorTypes extends { status: number; response: any }>(
      query: { q: string; limit: number },
      params?: RequestParams,
    ) =>
      this.request<ThinPerson[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/people${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags private, people
     * @name unsubscribe
     * @request DELETE:/people/subscriptions
     */
    unsubscribe: <ErrorTypes extends { status: number; response: any }>(
      query: { email: string; type: string },
      params?: RequestParams,
    ) =>
      this.request<object | null, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/people/subscriptions${this.addQueryParams(query)}`,
        "DELETE",
        params,
      ),
  };
  planner = {
    /**
     * @tags actions
     * @name pullTaskToCurrentWeek
     * @request POST:/planner/pull/task/{taskId}
     */
    pullTaskToCurrentWeek: <ErrorTypes extends { status: number; response: any }>(
      taskId: number,
      data: AbstractLinearTeam,
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<Task, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/planner/pull/task/${taskId}${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags actions
     * @name pushToWeek
     * @request POST:/planner/push/task/{taskId}/{weekOf}
     */
    pushToWeek: <ErrorTypes extends { status: number; response: any }>(
      taskId: number,
      weekOf: string,
      data: AbstractLinearTeam,
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<Task, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/planner/push/task/${taskId}/${weekOf}${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags actions
     * @name snoozeTask
     * @request POST:/planner/snooze/task/{eventId}/{minutes}
     */
    snoozeTask: <ErrorTypes extends { status: number; response: any }>(
      eventId: string,
      minutes: number,
      data: AbstractLinearTeam,
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<Task, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/planner/snooze/task/${eventId}/${minutes}${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),
  };
  priorities = {
    /**
     * @tags priorities
     * @name getPriorities
     * @request GET:/priorities
     * @secure
     */
    getPriorities: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<Priority[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/priorities`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags priorities
     * @name setPriorities
     * @request POST:/priorities
     */
    setPriorities: <ErrorTypes extends { status: number; response: any }>(
      data: { user?: User },
      params?: RequestParams,
    ) =>
      this.request<Priority[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/priorities`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags priorities
     * @name get
     * @request GET:/priorities/{id}
     * @secure
     */
    get: <ErrorTypes extends { status: number; response: any }>(id: number, params?: RequestParams) =>
      this.request<Priority, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/priorities/${id}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags priorities
     * @name put
     * @request PUT:/priorities/{id}
     */
    put: <ErrorTypes extends { status: number; response: any }>(
      id: number,
      data: Priority & { user?: User },
      params?: RequestParams,
    ) =>
      this.request<object | null, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/priorities/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @tags priorities
     * @name delete
     * @request DELETE:/priorities/{id}
     */
    delete: <ErrorTypes extends { status: number; response: any }>(
      id: number,
      data: { user?: User },
      params?: RequestParams,
    ) =>
      this.request<object | null, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/priorities/${id}`,
        "DELETE",
        params,
        data,
      ),
  };
  projects = {
    /**
     * @tags projects
     * @name query
     * @request GET:/projects
     */
    query: <ErrorTypes extends { status: number; response: any }>(
      query: { parameters: object[] },
      params?: RequestParams,
    ) =>
      this.request<Project[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/projects${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags projects
     * @name create
     * @request POST:/projects
     */
    create: <ErrorTypes extends { status: number; response: any }>(data: Project, params?: RequestParams) =>
      this.request<Project, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/projects`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags projects
     * @name get
     * @request GET:/projects/{id}
     */
    get: <ErrorTypes extends { status: number; response: any }>(id: number, params?: RequestParams) =>
      this.request<Project, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/projects/${id}`,
        "GET",
        params,
      ),

    /**
     * @tags projects
     * @name put
     * @request PUT:/projects/{id}
     */
    put: <ErrorTypes extends { status: number; response: any }>(id: number, data: Project, params?: RequestParams) =>
      this.request<Project, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/projects/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @tags projects
     * @name delete
     * @request DELETE:/projects/{id}
     */
    delete: <ErrorTypes extends { status: number; response: any }>(id: number, params?: RequestParams) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/projects/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @tags projects
     * @name patch
     * @request PATCH:/projects/{id}
     */
    patch: <ErrorTypes extends { status: number; response: any }>(id: number, data: Project, params?: RequestParams) =>
      this.request<Project, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/projects/${id}`,
        "PATCH",
        params,
        data,
      ),

    /**
     * @tags projects
     * @name getEvents
     * @request GET:/projects/{id}/events
     */
    getEvents: <ErrorTypes extends { status: number; response: any }>(id: number, params?: RequestParams) =>
      this.request<EventKey[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/projects/${id}/events`,
        "GET",
        params,
      ),

    /**
     * @tags projects
     * @name getTasks
     * @request GET:/projects/{id}/tasks
     */
    getTasks: <ErrorTypes extends { status: number; response: any }>(id: number, params?: RequestParams) =>
      this.request<number[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/projects/${id}/tasks`,
        "GET",
        params,
      ),

    /**
     * @tags projects
     * @name associateEvent
     * @request POST:/projects/{projectId}/events/{calendarId}/{eventId}
     */
    associateEvent: <ErrorTypes extends { status: number; response: any }>(
      projectId: number,
      calendarId: number,
      eventId: string,
      data: AbstractLinearTeam,
      params?: RequestParams,
    ) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/projects/${projectId}/events/${calendarId}/${eventId}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags projects
     * @name disassociateEvent
     * @request DELETE:/projects/{projectId}/events/{calendarId}/{eventId}
     */
    disassociateEvent: <ErrorTypes extends { status: number; response: any }>(
      projectId: number,
      calendarId: number,
      eventId: string,
      params?: RequestParams,
    ) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/projects/${projectId}/events/${calendarId}/${eventId}`,
        "DELETE",
        params,
      ),

    /**
     * @tags projects
     * @name associateTask
     * @request POST:/projects/{projectId}/tasks/{taskId}
     */
    associateTask: <ErrorTypes extends { status: number; response: any }>(
      projectId: number,
      taskId: number,
      data: AbstractLinearTeam,
      params?: RequestParams,
    ) =>
      this.request<any, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/projects/${projectId}/tasks/${taskId}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags projects
     * @name disassociateTask
     * @request DELETE:/projects/{projectId}/tasks/{taskId}
     */
    disassociateTask: <ErrorTypes extends { status: number; response: any }>(
      projectId: number,
      taskId: number,
      params?: RequestParams,
    ) =>
      this.request<any, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/projects/${projectId}/tasks/${taskId}`,
        "DELETE",
        params,
      ),
  };
  schedule = {
    /**
     * @tags schedule
     * @name query
     * @request GET:/schedule
     * @secure
     */
    query: <ErrorTypes extends { status: number; response: any }>(
      query: { q: string; start?: string | null; end?: string | null; limit?: number | null },
      params?: RequestParams,
    ) =>
      this.request<PlanItem[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/schedule${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags schedule
     * @name expandedPlan
     * @request GET:/schedule/expanded-plan
     * @secure
     */
    expandedPlan: <ErrorTypes extends { status: number; response: any }>(
      query?: { start?: string | null; end?: string | null },
      params?: RequestParams,
    ) =>
      this.request<ExpandedPlan, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/schedule/expanded-plan${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),
  };
  slack = {
    /**
     * @tags slack, integration, private
     * @name actionEndpoint
     * @request POST:/slack/action-endpoint
     */
    actionEndpoint: <ErrorTypes extends { status: number; response: any }>(
      data: AbstractLinearTeam,
      params?: RequestParams,
    ) =>
      this.request<object | null, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/slack/action-endpoint`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags slack, integration, private
     * @name slackRedirect
     * @request GET:/slack/add
     */
    slackRedirect: <ErrorTypes extends { status: number; response: any }>(
      query?: { scope?: string | null; bot?: string | null; teamId?: string | null },
      params?: RequestParams,
    ) =>
      this.request<object | null, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/slack/add${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags slack, integration, private
     * @name command
     * @request POST:/slack/command
     */
    command: <ErrorTypes extends { status: number; response: any }>(
      data: {
        command?: string | null;
        text?: string | null;
        response_url?: string | null;
        trigger_id?: string | null;
        user_id?: string | null;
        team_id?: string | null;
        enterprise_id?: string | null;
        channel_id?: string | null;
        ssl_check?: string | null;
      },
      params?: RequestParams,
    ) =>
      this.request<object | null, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/slack/command`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags slack, integration, private
     * @name connect
     * @request GET:/slack/connect
     */
    connect: <ErrorTypes extends { status: number; response: any }>(
      query?: { code?: string | null; error?: string | null },
      params?: RequestParams,
    ) =>
      this.request<object | null, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/slack/connect${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags slack, integration, private
     * @name interactiveEndpoint
     * @request POST:/slack/interactive-endpoint
     */
    interactiveEndpoint: <ErrorTypes extends { status: number; response: any }>(
      data: { response_url?: string | null; payload?: string | null },
      params?: RequestParams,
    ) =>
      this.request<object | null, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/slack/interactive-endpoint`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags slack, integration, private
     * @name link
     * @request POST:/slack/link
     */
    link: <ErrorTypes extends { status: number; response: any }>(
      query: { teamId: string; userId: string },
      data: { user?: User },
      params?: RequestParams,
    ) =>
      this.request<object | null, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/slack/link${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags slack, integration, private
     * @name optionsLoadEndpoint
     * @request POST:/slack/options-load-endpoint
     */
    optionsLoadEndpoint: <ErrorTypes extends { status: number; response: any }>(
      data: { response_url?: string | null; payload?: string | null },
      params?: RequestParams,
    ) =>
      this.request<object | null, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/slack/options-load-endpoint`,
        "POST",
        params,
        data,
      ),
  };
  tasks = {
    /**
     * @tags tasks
     * @name query
     * @request GET:/tasks
     * @secure
     */
    query: <ErrorTypes extends { status: number; response: any }>(
      query?: {
        status?: TaskStatus[] | null | null;
        project?: number | null;
        priority?: Smurf;
        id?: number[] | null | null;
        instances?: boolean | null;
        includeProjects?: ProjectInclude;
      },
      params?: RequestParams,
    ) =>
      this.request<Task[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/tasks${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags tasks
     * @name create
     * @request POST:/tasks
     */
    create: <ErrorTypes extends { status: number; response: any }>(
      data: Task & { user?: User },
      query?: { instances?: boolean | null; includeProjects?: ProjectInclude; notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<Task, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/tasks${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags tasks
     * @name interest
     * @request POST:/tasks/interest
     */
    interest: <ErrorTypes extends { status: number; response: any }>(data: { user?: User }, params?: RequestParams) =>
      this.request<object | null, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/tasks/interest`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags tasks
     * @name getTask
     * @request GET:/tasks/{id}
     * @secure
     */
    getTask: <ErrorTypes extends { status: number; response: any }>(
      id: number,
      query?: { instances?: boolean | null; includeProjects?: ProjectInclude },
      params?: RequestParams,
    ) =>
      this.request<Task, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/tasks/${id}${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags tasks
     * @name delete
     * @request DELETE:/tasks/{id}
     */
    delete: <ErrorTypes extends { status: number; response: any }>(
      id: number,
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<object | null, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/tasks/${id}${this.addQueryParams(query)}`,
        "DELETE",
        params,
      ),

    /**
     * @tags tasks
     * @name archive
     * @request POST:/tasks/{id}/archive
     */
    archive: <ErrorTypes extends { status: number; response: any }>(
      id: number,
      data: { user?: User },
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<Task, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/tasks/${id}/archive${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags tasks
     * @name cancel
     * @request POST:/tasks/{id}/cancel
     */
    cancel: <ErrorTypes extends { status: number; response: any }>(
      id: number,
      data: { user?: User },
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<Task, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/tasks/${id}/cancel${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags tasks
     * @name done
     * @request POST:/tasks/{id}/done
     */
    done: <ErrorTypes extends { status: number; response: any }>(
      id: number,
      data: { user?: User },
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<Task, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/tasks/${id}/done${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags tasks
     * @name extend
     * @request POST:/tasks/{id}/extend
     */
    extend: <ErrorTypes extends { status: number; response: any }>(
      id: number,
      query: { timeChunks: number; notificationKey?: string | null },
      data: { user?: User },
      params?: RequestParams,
    ) =>
      this.request<Task, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/tasks/${id}/extend${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags tasks
     * @name start
     * @request POST:/tasks/{id}/start
     */
    start: <ErrorTypes extends { status: number; response: any }>(
      id: number,
      data: { user?: User },
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<Task, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/tasks/${id}/start${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags tasks
     * @name stop
     * @request POST:/tasks/{id}/stop
     */
    stop: <ErrorTypes extends { status: number; response: any }>(
      id: number,
      data: { user?: User },
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<Task, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/tasks/${id}/stop${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags tasks
     * @name unarchive
     * @request POST:/tasks/{id}/unarchive
     */
    unarchive: <ErrorTypes extends { status: number; response: any }>(
      id: number,
      data: { user?: User },
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<Task, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/tasks/${id}/unarchive${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags tasks
     * @name update
     * @request PUT:/tasks/{taskId}
     */
    update: <ErrorTypes extends { status: number; response: any }>(
      taskId: number,
      data: Task & { user?: User },
      query?: { instances?: boolean | null; includeProjects?: ProjectInclude; notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<Task, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/tasks/${taskId}${this.addQueryParams(query)}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @tags tasks
     * @name patch
     * @request PATCH:/tasks/{taskId}
     */
    patch: <ErrorTypes extends { status: number; response: any }>(
      taskId: number,
      data: AbstractLinearTeam,
      query?: { instances?: boolean | null; includeProjects?: ProjectInclude; notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<Task, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/tasks/${taskId}${this.addQueryParams(query)}`,
        "PATCH",
        params,
        data,
      ),

    /**
     * @tags tasks
     * @name getInstances
     * @request GET:/tasks/{taskId}/instances
     * @secure
     */
    getInstances: <ErrorTypes extends { status: number; response: any }>(taskId: number, params?: RequestParams) =>
      this.request<
        TaskInstance[],
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/tasks/${taskId}/instances`, "GET", params, null, BodyType.Json, true),

    /**
     * @tags tasks
     * @name updateInstance
     * @request PATCH:/tasks/{taskId}/instances/{index}
     */
    updateInstance: <ErrorTypes extends { status: number; response: any }>(
      taskId: number,
      index: number,
      data: { user?: User },
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/tasks/${taskId}/instances/${index}${this.addQueryParams(query)}`,
        "PATCH",
        params,
        data,
      ),
  };
  users = {
    /**
     * @tags users
     * @name current
     * @summary User details for the currently authenticated user
     * @request GET:/users/current
     * @description User details for the currently authenticated user
     */
    current: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<User, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/users/current`,
        "GET",
        params,
      ),

    /**
     * @tags users
     * @name delete
     * @request DELETE:/users/current
     */
    delete: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<object | null, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/users/current`,
        "DELETE",
        params,
      ),

    /**
     * @tags users
     * @name patch
     * @request PATCH:/users/current
     */
    patch: <ErrorTypes extends { status: number; response: any }>(data: User, params?: RequestParams) =>
      this.request<User, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/users/current`,
        "PATCH",
        params,
        data,
      ),

    /**
     * @tags contacts
     * @name getContacts
     * @request GET:/users/current/contacts
     * @secure
     */
    getContacts: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<ThinPerson[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/users/current/contacts`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags contacts
     * @name inviteContacts
     * @request POST:/users/current/contacts/invite
     */
    inviteContacts: <ErrorTypes extends { status: number; response: any }>(
      data: ThinPerson[],
      params?: RequestParams,
    ) =>
      this.request<object | null, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/users/current/contacts/invite`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags users
     * @name referrals
     * @request GET:/users/current/referrals
     */
    referrals: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<ReferralStats, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/users/current/referrals`,
        "GET",
        params,
      ),

    /**
     * @tags users
     * @name interest
     * @request POST:/users/interest
     */
    interest: <ErrorTypes extends { status: number; response: any }>(
      query: { feature: string },
      data: AbstractLinearTeam,
      params?: RequestParams,
    ) =>
      this.request<User, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/users/interest${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags users
     * @name addTrait
     * @request POST:/users/trait/{trait}
     */
    addTrait: <ErrorTypes extends { status: number; response: any }>(
      trait: UserTrait,
      data: AbstractLinearTeam,
      params?: RequestParams,
    ) =>
      this.request<User, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/users/trait/${trait}`,
        "POST",
        params,
        data,
      ),
  };
  weeklyReport = {
    /**
     * @tags weekly-report
     * @name social
     * @request GET:/weekly-report/social
     */
    social: <ErrorTypes extends { status: number; response: any }>(
      query: { id: string; verificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<
        WeeklyReportTemplateData,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/weekly-report/social${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags weekly-report
     * @name socialImage
     * @request GET:/weekly-report/social/{id}.png
     */
    socialImage: <ErrorTypes extends { status: number; response: any }>(id: string, params?: RequestParams) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/weekly-report/social/${id}.png`,
        "GET",
        params,
      ),

    /**
     * @tags weekly-report
     * @name socialImageAlt
     * @request GET:/weekly-report/social/{trackingCode}/{suffix}.png
     */
    socialImageAlt: <ErrorTypes extends { status: number; response: any }>(
      trackingCode: string,
      suffix: string,
      params?: RequestParams,
    ) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/weekly-report/social/${trackingCode}/${suffix}.png`,
        "GET",
        params,
      ),

    /**
     * @tags weekly-report
     * @name unsubscribe
     * @request POST:/weekly-report/unsubscribe
     */
    unsubscribe: <ErrorTypes extends { status: number; response: any }>(
      data: WeeklyReportUnsubscribeRequest,
      params?: RequestParams,
    ) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/weekly-report/unsubscribe`,
        "POST",
        params,
        data,
      ),
  };
  avatar = {
    /**
     * @name credential
     * @request GET:/avatar/credential/{credentialId}
     */
    credential: <ErrorTypes extends { status: number; response: any }>(credentialId: number, params?: RequestParams) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/avatar/credential/${credentialId}`,
        "GET",
        params,
      ),

    /**
     * @name avatar
     * @request GET:/avatar/lookup
     */
    avatar: <ErrorTypes extends { status: number; response: any }>(query: { email: string }, params?: RequestParams) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/avatar/lookup${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @name me
     * @request GET:/avatar/me
     */
    me: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/avatar/me`,
        "GET",
        params,
      ),
  };
  aws = {
    /**
     * @tags aws-ses
     * @name sesEvent
     * @request POST:/aws/ses/events
     */
    sesEvent: <ErrorTypes extends { status: number; response: any }>(data: LocalTime, params?: RequestParams) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/aws/ses/events`,
        "POST",
        params,
        data,
      ),
  };
  oauth = {
    /**
     * @name accountInit
     * @request GET:/oauth/zoom/account/init
     */
    accountInit: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<object | null, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/oauth/zoom/account/init`,
        "GET",
        params,
      ),

    /**
     * @name initZoom
     * @request GET:/oauth/zoom/init
     */
    initZoom: <ErrorTypes extends { status: number; response: any }>(
      query?: { state?: string | null },
      params?: RequestParams,
    ) =>
      this.request<object | null, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/oauth/zoom/init${this.addQueryParams(query)}`,
        "GET",
        params,
      ),
  };
  ws = {
    /**
     * @tags websocket
     * @name swaggerHelper
     * @summary This is a bogus endpoint that gives us swagger generated objects for our websocket specific classes.
     * @request POST:/ws/bogus
     * @description This is a bogus endpoint that gives us swagger generated objects for our websocket specific classes.
     */
    swaggerHelper: <ErrorTypes extends { status: number; response: any }>(
      data: {
        user?: User;
        envelope?: WebSocketResponseEnvelope;
        assistCompleted?: AssistCompleted;
        assistPlanned?: AssistPlanned;
      },
      params?: RequestParams,
    ) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/ws/bogus`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags websocket
     * @name connect
     * @summary How do we get the connectionId?

 # List all integrations

 aws apigatewayv2 get-integrations --api-id xxxxxxxxx

 # Update all integration

 aws apigatewayv2 update-integration --integration-id zzzzzzzz --api-id xxxxxxxxx --request-parameters 'integration.request.header.connectionId'='context.connectionId'
     * @request POST:/ws/connect
     * @description How do we get the connectionId? # List all integrations aws apigatewayv2 get-integrations --api-id xxxxxxxxx # Update all integration aws apigatewayv2 update-integration --integration-id zzzzzzzz --api-id xxxxxxxxx --request-parameters 'integration.request.header.connectionId'='context.connectionId'
     */
    connect: <ErrorTypes extends { status: number; response: any }>(data: { user?: User }, params?: RequestParams) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/ws/connect`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags websocket
     * @name defaultHandler
     * @request POST:/ws/default
     */
    defaultHandler: <ErrorTypes extends { status: number; response: any }>(
      data: AbstractLinearTeam,
      params?: RequestParams,
    ) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/ws/default`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags websocket
     * @name disconnect
     * @request POST:/ws/disconnect
     */
    disconnect: <ErrorTypes extends { status: number; response: any }>(
      data: AbstractLinearTeam,
      params?: RequestParams,
    ) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/ws/disconnect`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags websocket
     * @name subscribe
     * @request POST:/ws/subscribe
     */
    subscribe: <ErrorTypes extends { status: number; response: any }>(
      data: { subscribeAction?: SubscribeAction },
      params?: RequestParams,
    ) =>
      this.request<
        WebSocketResponseEnvelope,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/ws/subscribe`, "POST", params, data),

    /**
     * @tags websocket
     * @name unsubscribe
     * @request POST:/ws/unsubscribe
     */
    unsubscribe: <ErrorTypes extends { status: number; response: any }>(
      data: { unsubscribeAction?: UnsubscribeAction },
      params?: RequestParams,
    ) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/ws/unsubscribe`,
        "POST",
        params,
        data,
      ),
  };
}
