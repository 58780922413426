import { concat, filter, fromPromise, map, pipe, Source } from "wonka";
import { Override } from "../types";
import { strToDate } from "../utils/dates";
import { deserialize } from "../utils/wonka";
import { SubscriptionType } from "./adaptors/ws";
import {
  ExpandedPlan as ExpandedPlanDto,
  HabitPlanItem as HabitPlanItemDto,
  PlanItem as PlanItemDto,
  Prioritizable as PrioritizableDto,
  PrioritizableActions as PrioritizableActionsDto,
  RecurringOneOnOne as RecurringOneOnOneDto,
  TaskPlanItem as TaskPlanItemDto,
} from "./client";
import { dtoToHabit, Habit } from "./Habits";
import { dtoToRecurringOneOnOne, RecurringOneOnOne } from "./OneOnOnes";
import { Smurf } from "./Projects";
import { dtoToTask, Task } from "./Tasks";
import { TransformDomain } from "./types";

export enum PlanItemType {
  Task = "TASK",
  Habit = "HABIT",
  OneOnOne = "ONE_ON_ONE",
}

export enum PlanItemStatus {
  Scheduled = "SCHEDULED",
  Unscheduled = "UNSCHEDULED",
  Completed = "COMPLETED",
}

// export type Prioritizable = {
//   priority: Smurf;
//   priorityUntil: string;
//   effectivePriority: Smurf;
// };

export type Prioritizable = PrioritizableDto;

export type PrioritizableActions = PrioritizableActionsDto;

export type TaskActions = PrioritizableActions & {};

export type HabitActions = PrioritizableActions & {};

export type OneOnOneActions = PrioritizableActions & {};

export interface PlanItem
  extends Override<
    PlanItemDto,
    {
      readonly actions?: PrioritizableActions;

      type: PlanItemType;
      status: PlanItemStatus;
      data: Task | Habit | RecurringOneOnOne;
      priority: Smurf;
    }
  > {}

export type TaskPlanItem = Override<
  TaskPlanItemDto,
  {
    readonly actions?: TaskActions;

    timeChunksRequired: number;
    timeChunksSpent: number;
    timeChunksRemaining: number;
    percentComplete: number;
    overdue: boolean;
    atRisk: boolean;
  }
> &
  Override<
    PlanItem,
    {
      type: PlanItemType.Task;
      data: Task;
    }
  >;

export type HabitPlanItem = Override<
  HabitPlanItemDto,
  {
    readonly actions?: HabitActions;

    maxPossible: number;
    completed: number;
    scheduled: number;
  }
> &
  Override<
    PlanItem,
    {
      type: PlanItemType.Habit;
      data: Habit;
    }
  >;

export type OneOnOnePlanItem = {
  readonly actions?: OneOnOneActions;

  doneDate?: Date;
} & Override<
  PlanItem,
  {
    type: PlanItemType.OneOnOne;
    data: RecurringOneOnOne;
  }
>;

export type ExpandedPlan = Override<
  ExpandedPlanDto,
  {
    oneOnOnes?: OneOnOnePlanItem[];
    scheduled: (TaskPlanItem | HabitPlanItem)[];
    atRisk: (TaskPlanItem | HabitPlanItem)[];
    recentTasks: (TaskPlanItem | HabitPlanItem)[];
    done: (TaskPlanItem | HabitPlanItem)[];
  }
>;

/** PLAN ITEMS DOMAIN */

export function dtoToPlanItem<R extends PlanItem>(dto: PlanItemDto): R {
  const data = {
    ...dto,
  } as unknown as R;

  if (!!dto.data) {
    if ("ONE_ON_ONE" === dto.type) data.data = dtoToRecurringOneOnOne(dto.data as RecurringOneOnOneDto);
    else if ("TASK" === dto.type) data.data = dtoToTask(dto.data);
    else if ("HABIT" === dto.type) data.data = dtoToHabit(dto.data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    else data.data = dto.data as any;
  }

  if (!!dto["doneDate"]) {
    data["doneDate"] = strToDate(dto["doneDate"]);
  }

  return data;
}

export function dtoToTypedPlanItem(dto: PlanItemDto) {
  // if ("ONE_ON_ONE" === dto.type) return dtoToPlanItem<OneOnOnePlanItem>(dto);
  if ("TASK" === dto.type) return dtoToPlanItem<TaskPlanItem>(dto);
  // else if ("HABIT" === dto.type) return dtoToPlanItem<HabitPlanItem>(dto);
  else return dtoToPlanItem<HabitPlanItem>(dto);
}
export class PlanItemsDomain extends TransformDomain<PlanItem, PlanItemDto> {
  resource = "PlanItem";
  cacheKey = "planItems";
  pk = ["type", "data.id"];

  public deserialize = dtoToTypedPlanItem;

  search = this.manageErrors(
    this.deserializeResponse((q: string, start: Date, end: Date) => {
      return this.api.schedule.query({ q, start: start.toISOString(), end: end.toISOString() });
    })
  );
}

/** EXPANDED PLAN DOMAIN */

export function dtoToExpandedPlan(dto: ExpandedPlanDto): ExpandedPlan {
  return {
    ...dto,
    oneOnOnes: dto.oneOnOnes?.map((dto) => dtoToPlanItem<OneOnOnePlanItem>(dto)) || [],
    scheduled: dto.scheduled?.map(dtoToTypedPlanItem) || [],
    atRisk: dto.atRisk?.map(dtoToTypedPlanItem) || [],
    recentTasks: dto.recentTasks?.map(dtoToTypedPlanItem) || [],
    done: dto.done?.map(dtoToTypedPlanItem) || [],
  };
}

// TODO (IW): Implement properly when needed
export function expandedPlanToDto(plan: Partial<ExpandedPlan>): Partial<ExpandedPlanDto> {
  return plan as Partial<ExpandedPlanDto>;
}

const AssistPlannedSubscription = {
  subscriptionType: SubscriptionType.AssistCompleted,
};

export class ExpandedPlanDomain extends TransformDomain<ExpandedPlan, ExpandedPlanDto> {
  resource = "ExpandedPlan";
  cacheKey = "expandedPlans";
  pk = "id"; // FIXME (IW): No actual PK (perhaps add start/end to payload)

  public deserialize = dtoToExpandedPlan;
  public serialize = expandedPlanToDto;

  watchAll$ = pipe(
    this.ws.subscription$$(AssistPlannedSubscription),
    filter((envelope) => !!envelope.data?.expandedPlan),
    map((envelope) => envelope.data.expandedPlan),
    deserialize(this.deserialize),
    map((items) => this.patchExpectedChanges(items)),
    // Schedule is only one item
    map((items) => items[0])
  );

  watch$$ = (start: Date, end: Date) => {
    const subscription = {
      subscriptionType: SubscriptionType.AssistPlanned,
      startTime: start,
      endTime: end,
    };

    return pipe(
      this.ws.subscription$$(subscription),
      filter((envelope) => !!envelope.data?.expandedPlan),
      map((envelope) => envelope.data.expandedPlan),
      deserialize(this.deserialize),
      map((items) => this.patchExpectedChanges(items)),
      // Schedule is only one item
      map((items) => items[0])
    );
  };

  list$$ = (start: Date, end: Date): Source<ExpandedPlan> =>
    pipe(
      fromPromise(this.get(start, end)),
      map((items) => this.patchExpectedChanges(items))
    );

  listAndWatch$$ = (start: Date, end: Date): Source<ExpandedPlan> => {
    return concat([this.list$$(start, end), this.watch$$(start, end)]);
  };

  get = this.manageErrors(
    this.deserializeResponse((start: Date, end: Date) => {
      return this.api.schedule.expandedPlan({
        start: start.toISOString(),
        end: end.toISOString(),
      });
    })
  );
}
